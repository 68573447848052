const Psychometrics = ({ user }) => {
  const psychometric = user?.psychometric;
  return (
    <div className="w-full flex flex-col p-10 lg:p-16">
            <div className="text-5xl font-bold text-black mb-10">Compatibility Test Result</div>

      <div className="text-3xl font-bold italic">{psychometric?.resultType?.name}</div>
      <div className="mt-2 text-base text-gray-600">{psychometric?.resultType?.description}</div>
      <div className="flex flex-wrap gap-3 mt-5">
        {psychometric?.traitList?.map((trait, index) => {
          return (
            <div
              key={index}
              className="rounded-lg shadow-md w-[48%] p-3 border"
              style={{ backgroundColor: trait.backgroundColor }}
            >
              <div className="mb-2 text-lg font-bold underline" style={{ color: trait.titleColor }}>
                {trait.traitTypeName}
              </div>
              <div className="flex flex-row gap-x-3 items-start justify-start">
                <div
                  style={{ backgroundColor: trait.titleColor }}
                  className="flex-shrink-0 w-10 h-10 rounded-full flex justify-center items-center text-xl text-white font-bold shadow-md"
                >
                  {trait?.traitShort}
                </div>
                <div className="text-sm font-medium text-black/80">{trait.traitName}</div>
              </div>
            </div>
          );
        })}
      </div>
      <div className="mt-10 text-3xl font-bold">Strengths</div>
      <div className="mt-3 flex flex-col gap-3">
        {psychometric?.strengthList?.map((strength, index) => {
          return (
            <div key={index}>
              <div className="text-lg font-semibold">{strength?.strengthTitle}</div>
              <div className="text-sm font-medium text-gray-400">{strength?.strengthDescription}</div>
              <div></div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Psychometrics;
