import ItemTile from './itemSkill';

const AboutMe = ({ user }) => {
  return (
    <div className="w-full flex flex-col">
      {user?.skills?.length > 0 && (
        <div className="flex flex-col bg-template9-orange-1 px-10 py-10 lg:px-16 lg:py-10">
          <div className="text-4xl lg:text-5xl font-bold mb-10 lg:mb-8 text-template9-gray-2">My Skills</div>
          <div className="text-base font-medium w-full pt-5 lg:pt-0 flex flex-wrap gap-5 justify-center lg:justify-start">
            {user?.skills?.map((skill, index) => {
              return <ItemTile item={skill?.skill} level={skill?.level} key={index} />;
            })}
          </div>
        </div>
      )}
      {user?.languages?.length > 0 && (
        <div className='px-10 py-10  lg:px-16 lg:py-10'>
          <div className="text-4xl lg:text-5xl font-bold mb-10 lg:mb-10 text-white">Languages</div>
          <div className="text-base font-medium w-full pt-5 lg:pt-0 mt-1 flex flex-wrap gap-5 justify-center lg:justify-start">
            {user?.languages?.map((lang, index) => {
              const isFirstIndex = index === 0 ? true : false;
              return <ItemTile item={lang?.lang} level={lang?.level} key={index} isFirstIndex={isFirstIndex} />;
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default AboutMe;
