import ReactPlayer from 'react-player';

const Video = ({ user }) => {
  return (
    <div className="w-full flex flex-col px-10 pb-10 lg:px-16 lg:pb-10 shadow-md bg-white">
      <div className="text-5xl font-bold lg:w-1/2 ">Video</div>
      <div className="text-lg mb-5">Check out my video</div>
      <div className="flex flex-col pt-5 w-full">
        <div style={{ border: '2px solid green' }} className="bg-template4-green-4 w-full lg:w-[550px]">
          <ReactPlayer url={user?.video?.videoUrl} controls={true} width="100%" />
        </div>
      </div>
    </div>
  );
};

export default Video;
