import { useParams } from 'react-router-dom';
import Introduction from './introduction';
import Header from '../../../common/header';
import Footer from '../../../common/footer';
import CompanyDetails from './companyDetails';
import JobDescription from './jobDescription';
import Benefits from './benefits';
import AvailableJobs from './availableJobs';
import { useDispatch } from 'react-redux';
import { getJobById } from '../../../actions/jobAction';
import { useEffect } from 'react';

const JobDetails = () => {
  const dispatch = useDispatch();
  const { job } = useParams();

  useEffect(() => {
    dispatch(getJobById(job));
  }, []);

  return (
    <div className="flex flex-col min-h-screen">
      <Header />
      <div className="flex flex-col justify-center items-center w-full">
        <div className="w-full max-w-[1200px] bg-white rounded-xl">
          <Introduction />
          <CompanyDetails />
          <JobDescription />
          <Benefits />
          <AvailableJobs />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default JobDetails;
