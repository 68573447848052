import { useSelector } from "react-redux";
import PsychometricTile from "../../../common/psychometricTile";

const PsychometricCareer = ({ psychometric }) => {
  const lang = useSelector((state) => state.language.lang);

  return (
    <div>
      <div className="mt-10 text-3xl font-bold">{lang.generalCompatibleCareer}</div>
      <div className="mt-3">{psychometric?.resultType?.careerPathDesc}</div>
      <div className="mt-5 flex flex-col gap-5">
        {psychometric?.industryList?.map((industry, index) => {
          const jobList = psychometric?.jobList?.filter(job => job?.industryId === industry?.industryId)
          return(
            <div key={index}>
              <div className="bg-oppty-blue text-white py-1 px-3 w-fit font-semibold rounded-md -mb-[1.5px]">{industry?.industryTitle}</div>
              <div className="border-b-2 ml-[5px] border-oppty-blue" />
              <div className="mt-1 text-sm font-medium">{industry?.industryDescription}</div>
              <div className="flex flex-wrap gap-2 mt-3">
                {jobList?.map((job, index) => {
                  return <PsychometricTile key={index} job={job?.jobTitle}/>
                })}
              </div>
            </div>
          )
        })}
      </div>
    </div>
  );
};

export default PsychometricCareer;
