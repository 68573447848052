import { useSelector } from 'react-redux';
import defaultCompanyPic from '../../assets/images/defaultCompany.png';

const AllCompanies = () => {
  const lang = useSelector((state) => state.language.lang);
  const logos = useSelector((state) => state.public?.companyLogos);

  return (
    <div className="p-10 min-h-screen bg-white rounded-xl">
      <div className="text-4xl font-bold pb-10 text-center">{lang.generalCompanies}</div>
      <div className="flex flex-wrap gap-x-2 gap-y-8">
        {logos?.map((logo, index) => {
          return (
            logo?.companyName && (
              <div
                className="flex flex-col items-center cursor-pointer w-[270px]"
                onClick={() => window.open(`/employers/${logo?._id}`, '_blank')}
              >
                <img
                  src={logo?.companyLogo ? logo?.companyLogo : defaultCompanyPic}
                  alt={logo?.companyName}
                  key={index}
                  className="h-32 w-fit rounded-md hover:shadow-lg cursor-pointer transform transition-transform duration-300 hover:scale-110 mb-1"
                  onClick={() => window.open(`/employers/${logo?._id}`, '_blank')}
                />
                <span className="text-lg font-bold text-center w-full">{logo?.companyName}</span>
              </div>
            )
          );
        })}
      </div>
    </div>
  );
};

export default AllCompanies;
