import EducationCard from './educationCard';

const Education = ({ user }) => {
  return (
    <div className="w-full flex flex-col">
      <div className="flex flex-col lg:flex-row">
        <div className="flex flex-col w-full  p-10 lg:p-16">
          <div className="text-5xl font-bold mb-16 text-white">Education & Certification</div>
          <div className="flex flex-wrap flex-row gap-14">
            {user?.educations?.map((education, index) => {
              return <EducationCard key={index} education={education} />;
            })}
          </div>
          {user?.certifications?.length > 0 && (
            <div className="text-xl font-semibold my-10 text-white uppercase underline">Certifications</div>
          )}
          <div className="flex flex-wrap flex-row gap-14">
            {user?.certifications?.map((certification, index) => {
              return <EducationCard key={index} education={certification} />;
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Education;
