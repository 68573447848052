import PortfolioCard from './portfolioCard';

const Portfolio = ({ user }) => {
  return (
    <div className="w-full flex flex-col pb-10 px-10 lg:px-16 lg:pb-16 bg-white justify-center items-center relative">
      <div className="text-[#604E56] pb-10 w-full">
        <div className="text-5xl leading-none font-extrabold">My Portfolio</div>
      </div>
      <div className="flex flex-wrap w-full gap-8 justify-center bg-[#FFF6ED] lg:p-10">
        {user?.portfolios?.map((portfolio, index) => {
          return <PortfolioCard key={index} portfolio={portfolio} />;
        })}
      </div>
    </div>
  );
};

export default Portfolio;
