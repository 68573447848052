import { useSelector } from 'react-redux';
import { Link as ScrollLink } from 'react-scroll';

const EditProfileHeader = ({ profileStrength }) => {
  const lang = useSelector((state) => state.language.lang);

  return (
    <div className="flex flex-col px-5 lg:px-16">
      <div className="flex flex-row flex-wrap justify-between items-center">
        <div className="flex flex-col">
          <div className="text-3xl font-raleway font-bold leading-normal text-black">{lang.profileLetsSetup}</div>
        </div>
        <div className="flex flex-row w-36 justify-center items-center">
          <div className="w-full bg-gray-300 rounded-full dark:bg-gray-700 mt-1">
            <div
              className="bg-black text-xs font-bold text-white text-center p-1 leading-none rounded-full"
              style={{ width: `${profileStrength === 0 ? '10' : profileStrength}%` }}
            >
              {profileStrength}%
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-wrap pt-5 gap-x-10 gap-y-1 items-center">
        <div className="text-sm font-nunito font-semibold leading-normal text-oppty-grey-16">
          {lang.profileQuickNavigation}:
        </div>
        <ScrollLink
          to="generalDetails"
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
          className="text-oppty-blue text-lg font-semibold font-nunito leading-normal hover:text-black/50 hover:underline"
          style={{ cursor: 'pointer' }}
        >
          {lang.profileEditProfile}
        </ScrollLink>
        <ScrollLink
          to="skills"
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
          className="text-oppty-blue text-lg font-semibold font-nunito leading-normal hover:text-black/50 hover:underline"
          style={{ cursor: 'pointer' }}
        >
          {lang.generalSkills}
        </ScrollLink>
        <ScrollLink
          to="experience"
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
          className="text-oppty-blue text-lg font-semibold font-nunito leading-normal hover:text-black/50 hover:underline"
          style={{ cursor: 'pointer' }}
        >
          {lang.profileWorkExperience}
        </ScrollLink>
        <ScrollLink
          to="portfolios"
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
          className="text-oppty-blue text-lg font-semibold font-nunito leading-normal hover:text-black/50 hover:underline"
          style={{ cursor: 'pointer' }}
        >
          {lang.profilePortfolios}
        </ScrollLink>
        <ScrollLink
          to="education"
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
          className="text-oppty-blue text-lg font-semibold font-nunito leading-normal hover:text-black/50 hover:underline"
          style={{ cursor: 'pointer' }}
        >
          {lang.profileEducation}
        </ScrollLink>
        <ScrollLink
          to="certification"
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
          className="text-oppty-blue text-lg font-semibold font-nunito leading-normal hover:text-black/50 hover:underline"
          style={{ cursor: 'pointer' }}
        >
          {lang.profileCertification}
        </ScrollLink>
        <ScrollLink
          to="languages"
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
          className="text-oppty-blue text-lg font-semibold font-nunito leading-normal hover:text-black/50 hover:underline"
          style={{ cursor: 'pointer' }}
        >
          {lang.profileLanguages}
        </ScrollLink>
        <ScrollLink
          to="socials"
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
          className="text-oppty-blue text-lg font-semibold font-nunito leading-normal hover:text-black/50 hover:underline"
          style={{ cursor: 'pointer' }}
        >
          {lang.companySocials}
        </ScrollLink>
        <ScrollLink
          to="uploadResume"
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
          className="text-oppty-blue text-lg font-semibold font-nunito leading-normal hover:text-black/50 hover:underline"
          style={{ cursor: 'pointer' }}
        >
          {lang.jobUploadResume}
        </ScrollLink>
        <ScrollLink
          to="uploadVideo"
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
          className="text-oppty-blue text-lg font-semibold font-nunito leading-normal hover:text-black/50 hover:underline"
          style={{ cursor: 'pointer' }}
        >
          {lang.jobUploadVideo}
        </ScrollLink>
        <ScrollLink
          to="selectTemplate"
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
          className="text-oppty-blue text-lg font-semibold font-nunito leading-normal hover:text-black/50 hover:underline"
          style={{ cursor: 'pointer' }}
        >
          {lang.profileSelectTemplate}
        </ScrollLink>
        <ScrollLink
          to="testimonial"
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
          className="text-oppty-blue text-lg font-semibold font-nunito leading-normal hover:text-black/50 hover:underline"
          style={{ cursor: 'pointer' }}
        >
          {lang.profileTestimonial}
        </ScrollLink>
        <ScrollLink
          to="psychometric"
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
          className="text-oppty-blue text-lg font-semibold font-nunito leading-normal hover:text-black/50 hover:underline"
          style={{ cursor: 'pointer' }}
        >
          {lang.generalCompatibilityTest}
        </ScrollLink>
      </div>
    </div>
  );
};

export default EditProfileHeader;
