import { useDispatch } from 'react-redux';
import Footer from '../../common/footer';
import Header from '../../common/header';
import { useEffect } from 'react';
import { getAllPsychometricsQuestions } from '../../actions/psychrometricAction';
import AllQuestions from './allQuestions';

const Psychometric = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllPsychometricsQuestions());
    
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="flex flex-col min-h-screen items-center w-full">
      <Header />
      <div className="w-full max-w-[1200px]">
        <AllQuestions />
      </div>
      <Footer />
    </div>
  );
};

export default Psychometric;
