// import { ReactComponent as Facebook } from '../../../assets/icons/icons8-facebook.svg';
// import { ReactComponent as Instagram } from '../../../assets/icons/icons8-insta.svg';
// import { ReactComponent as Twitter } from '../../../assets/icons/icons8-twitter.svg';
import { ReactComponent as Design } from '../../../assets/templates/template2/headerDesign.svg';
import { ReactComponent as Facebook } from '../../../assets/icons/icons8-facebook.svg';
import { ReactComponent as Instagram } from '../../../assets/icons/icons8-insta.svg';
import { ReactComponent as Twitter } from '../../../assets/icons/icons8-twitterx.svg';
import { ReactComponent as Linkedin } from '../../../assets/icons/icons8-linkedin.svg';
import { ReactComponent as Github } from '../../../assets/icons/icons8-github.svg';
import { ReactComponent as Pinterest } from '../../../assets/icons/icons8-pinterest.svg';
import { ReactComponent as Youtube } from '../../../assets/icons/icons8-youtube.svg';
import { ArrowDownTrayIcon } from '@heroicons/react/24/outline';

const HeaderSection = ({ user }) => {
  const handleOpenLink = (link) => {
    console.log('open');

    if (!link.includes('http://') && !link.includes('https://')) {
      link = 'https://' + link;
    }

    window.open(link);
  };
  return (
    <div className="relative flex h-full flex-col justify-between w-full">
      <div className="flex flex-col h-full px-5 md:px-20 lg:px-32 pt-10 pb-28">
        {/* <div className="font-light flex gap-x-10 cursor-pointer justify-end w-full =">
          <div className="text-black/80 hover:underline">Home</div>
          <div className="hover:underline">Service</div>
          <div className="hover:underline">Portfolio</div>
          <div className="hover:underline">Blog</div>
          <div className="hover:underline">Contact</div>
        </div> */}
        <div className="font-extrabold flex flex-col justify-end items-end w-full py-5 lg:py-20">
          <div className="text-3xl font-light">Hi, This is</div>
          <div className="text-7xl text-right z-50">{user?.firstName}</div>
        </div>
        <div className="flex flex-col md:flex-row gap-10 md:gap-20 lg:gap-30 pt-10 justify-center items-center lg:justify-between lg:items-start">
          <div className="flex flex-col gap-y-10">
            {user?.title && <div className="font-bold text-3xl z-50">I'm a {user?.title}</div>}
            {/* <div className="-mt-5">{user?.aboutMe}</div> */}
            <div className="w-full mt-2" dangerouslySetInnerHTML={{ __html: user?.aboutMe }}></div>
          </div>
          <div className="flex flex-col justify-center items-center lg:items-end w-full gap-5 ">
            <div className="w-96 h-[500px] rounded-3xl bg-gray-300 overflow-hidden">
              {user?.avatar ? (
                <img src={user?.avatar} alt="pic" className="w-full h-full object-cover rounded-lg" />
              ) : (
                <svg
                  viewBox="0 0 444 482"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="p-5 lg:p-12 w-full h-full"
                >
                  <path
                    d="M222 241C255.645 241 287.911 228.331 311.702 205.78C335.492 183.228 348.857 152.642 348.857 120.75C348.857 88.8577 335.492 58.2717 311.702 35.7204C287.911 13.1692 255.645 0.5 222 0.5C188.355 0.5 156.089 13.1692 132.298 35.7204C108.508 58.2717 95.1429 88.8577 95.1429 120.75C95.1429 152.642 108.508 183.228 132.298 205.78C156.089 228.331 188.355 241 222 241ZM176.708 286.094C79.0875 286.094 0 361.062 0 453.598C0 469.005 13.1813 481.5 29.4348 481.5H414.565C430.819 481.5 444 469.005 444 453.598C444 361.062 364.913 286.094 267.292 286.094H176.708Z"
                    fill="rgba(60, 84, 189)"
                    fill-opacity="0.12"
                  />
                </svg>
              )}
            </div>
            <div className='flex items-start justify-center w-full'>
              <button
                disabled={!user?.resume}
                onClick={() => window.open(user?.resume?.resumeUrl)}
                className="text-center shadow-lg h-12 w-48 lg:mt-5 px-6 py-3 disabled:bg-gray-400 disabled:text-white bg-black/90 hover:bg-black/80 text-white text-sm font-semibold rounded-lg"
              >
                {user?.resume ? 'Download CV' : 'No CV Uploaded'}
              </button>
            </div>
            <div className="flex gap-x-1.5 lg:gap-x-3 flex-wrap items-center">
              {user?.socials?.map((social, index) => {
                return (
                  <div key={index}>
                    {social?.social === 'Linkedin' && (
                      <Linkedin
                        onClick={() => handleOpenLink(social.link)}
                        className="fill-template2-orange-1 hover:fill-orange-400 w-8 h-8 cursor-pointer"
                      />
                    )}
                    {social?.social === 'Facebook' && (
                      <Facebook
                        onClick={() => handleOpenLink(social.link)}
                        className="fill-template2-orange-1 hover:fill-orange-400  w-8 h-8 cursor-pointer"
                      />
                    )}
                    {social?.social === 'Instagram' && (
                      <Instagram
                        onClick={() => handleOpenLink(social.link)}
                        className="fill-template2-orange-1 hover:fill-orange-400  w-8 h-8 cursor-pointer"
                      />
                    )}
                    {social?.social === 'X' && (
                      <Twitter
                        onClick={() => handleOpenLink(social.link)}
                        className="fill-template2-orange-1 hover:fill-orange-400  w-8 h-8 cursor-pointer"
                      />
                    )}
                    {social?.social === 'Github' && (
                      <Github
                        onClick={() => handleOpenLink(social.link)}
                        className="fill-template2-orange-1 hover:fill-orange-400  w-8 h-8 cursor-pointer"
                      />
                    )}
                    {social?.social === 'Pinterest' && (
                      <Pinterest
                        onClick={() => handleOpenLink(social.link)}
                        className="fill-template2-orange-1 hover:fill-orange-400  w-8 h-8 cursor-pointer"
                      />
                    )}
                    {social?.social === 'Youtube' && (
                      <Youtube
                        onClick={() => handleOpenLink(social.link)}
                        className="fill-template2-orange-1 hover:fill-orange-400  w-9 h-9 cursor-pointer"
                      />
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <div className="absolute -top-20 h-[450px] w-[450px] hidden xl:flex">
        <Design className="w-full h-full" />
      </div>
    </div>
  );
};

export default HeaderSection;
