import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import QuestionAndAnswerCard from './questionAndAnswerCard';
import { ToastBar } from '../../common/toastbar';
import { submitPsychometricTest } from '../../actions/psychrometricAction';
import { useNavigate } from 'react-router-dom';

const localLang = localStorage.getItem('lan');

const AllQuestions = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const lang = useSelector((state) => state.language.lang);
  const allQuestions = useSelector((state) => state.psychometric.allQuestions);
  const [selectedAnswers, setSelectedAnswers] = useState([]);

  const handleAnswerSelect = (questionId, answerId, traitId) => {
    setSelectedAnswers((prev) => {
      const existingAnswerIndex = prev.findIndex((item) => item.questionId === questionId);

      if (existingAnswerIndex !== -1) {
        const updatedAnswers = [...prev];
        updatedAnswers[existingAnswerIndex] = { questionId, answerId, traitId };
        return updatedAnswers;
      } else {
        return [...prev, { questionId, answerId, traitId }];
      }
    });
  };

  const allAnswered = allQuestions.length === selectedAnswers.length;

  const handleSubmit = () => {
    if (!allAnswered) {
      ToastBar.warning(['Please answer all the questions!', '请回答所有问题！', 'Sila jawab semua soalan!'][localLang]);
      return;
    }
    const submissionData = selectedAnswers.map(({ questionId, answerId, traitId, mark=1 }) => ({ questionId, answerId, traitId, mark:1 }));
    console.log('Submitted answers:', submissionData);

    dispatch(submitPsychometricTest({ allAnswers: submissionData }, navigate));
  };

  console.log('selectedAnswers:', selectedAnswers);

  return (
    <div className="p-5 lg:p-10 min-h-screen bg-white rounded-xl">
      <div className="text-4xl font-bold pb-10 text-center">{lang.generalCompatibilityTest}</div>
      <div className="flex flex-col gap-4">
        {allQuestions.map((ques, index) => {
          return (
            <QuestionAndAnswerCard
              key={index}
              question={ques}
              index={index}
              selectedAnswers={selectedAnswers}
              handleAnswerSelect={handleAnswerSelect}
            />
          );
        })}
      </div>
      <div className="flex flex-row gap-x-2 justify-end">
        <button
          className="mt-10 bg-red-500 hover:bg-red-500/80 text-white font-bold text-lg px-4 py-2 rounded shadow-md"
          onClick={() => navigate('/profile')}
        >
          {lang.profileCancel}
        </button>
        <button
          className="mt-10 bg-oppty-blue hover:bg-oppty-blue/80 text-white font-bold text-lg px-4 py-2 rounded shadow-md"
          onClick={handleSubmit}
        >
          {lang.generalSubmit}
        </button>
      </div>
    </div>
  );
};

export default AllQuestions;
