import { useSelector } from 'react-redux';
import oval from '../../../assets/images/jobOval.svg';

const JobDescription = () => {
  const lang = useSelector((state) => state.language.lang);
  const job = useSelector((state) => state.selectedJob);

  return (
    <div className="flex flex-col justify-center px-5 lg:px-16">
      <div className="w-full lg:w-3/4">
        <div className="">
          <div className="text-4xl font-nunito font-bold text-black pb-8">{lang.generalJobDescription}</div>
          <div
            className="text-black"
            dangerouslySetInnerHTML={{ __html: job?.job?.jobDescription }}
          ></div>
        </div>
        <div className="py-12">
          <div className="text-4xl font-nunito font-bold text-black pb-8">{lang.generalResponsibilities}</div>
          <div
            className="flex flex-col gap-y-3"
            dangerouslySetInnerHTML={{ __html: job?.job?.jobResponsibilities }}
          ></div>
        </div>
        <div className="pb-12">
          <div className="text-4xl font-nunito font-bold text-black pb-8">{lang.jobRequirements}</div>
          <div className="gap-y-3" dangerouslySetInnerHTML={{ __html: job?.job?.jobRequirements }}></div>
        </div>
      </div>
    </div>
  );
};

export default JobDescription;
