import LangTile from './langTile';
import SkillTile from './skillTile';

const AboutMe = ({ user }) => {
  return (
    <div className="w-full flex flex-col justify-between p-10 lg:px-16 lg:py-10 shadow-md bg-template8-gray-1 gap-x-10">
      {user?.skills?.length > 0 && (
        <div className="w-full py-10 lg:pb-16 flex flex-col lg:flex-row border-b-[1px] border-gray-300">
          <div className="text-4xl font-bold underline w-full lg:w-1/2 text-black">My Skills</div>
          <div className="text-base font-medium w-full lg:w-1/2 pt-5 lg:pt-0 mt-1 flex flex-wrap gap-5">
            {user?.skills?.map((skill, index) => (
              <SkillTile item={skill?.skill} level={skill?.level} key={index} />
            ))}
          </div>
        </div>
      )}
      {user?.languages?.length > 0 && (
        <div className="w-full py-10 lg:py-16 flex flex-col lg:flex-row border-b-[1px] border-gray-300">
          <div className="text-4xl font-bold underline w-full lg:w-1/2 text-black">Languages</div>
          <div className="text-base font-medium w-full lg:w-1/2 pt-5 lg:pt-0 mt-1 flex flex-wrap gap-5">
            {user?.languages?.map((lang, index) => (
              <LangTile item={lang?.lang} level={lang?.level} key={index} />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default AboutMe;
