import { ReactComponent as Design } from '../../../assets/templates/template2/educationDesign.svg';
import EducationCard from './educationCard';

const EducationSection = ({ user }) => {
  return (
    <div className="relative flex flex-col">
      <div className="flex flex-col p-10 lg:p-16">
        <div className="w-full flex flex-col gap-y-20">
          <div className="text-[50px] font-bold">Education & Certification</div>
        </div>

        {user?.educations?.map((education, index) => {
          return <EducationCard key={index} education={education} />;
        })}
        {user?.certifications?.map((certification, index) => {
          const isLast = index === user?.certifications?.length - 1 && true;
          return <EducationCard key={index} education={certification} isLast={isLast} />;
        })}
      </div>
      {/* <div className="absolute h-[300px] w-[400px] -right-40 bottom-0">
        <Design className="w-full h-full hidden lg:flex" />
      </div> */}
    </div>
  );
};

export default EducationSection;
