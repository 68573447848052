import { useSelector } from 'react-redux'
import correctIcon from '../../../assets/images/correctIcon.svg'

const SeeHowPlatform = () => {
  const lang = useSelector((state) => state.language.lang)

  const embedUrl = 'https://www.youtube.com/embed/pbhjaWovo8M?si=_UoU7R1mq6TaaXjZ'

  return (
    <div className="flex flex-col lg:flex-row justify-center items-start gap-10 lg:gap-32 px-2 md:px-14 py-10">
      <div className="w-full lg:w-[40%] flex flex-col gap-3 md:gap-5">
        <div className="text-[15px] md:text-[25px] lg:text-[32px] font-raleway font-bold lg:leading-[46px] text-oppty-black-6">
          {lang.clientSeeHow}
        </div>
        <div className="text-[10px] md:text-[14px] lg:text-[18px] font-raleway font-medium lg:leading-[32px] text-oppty-black-6">
          {lang.clientTrackYourCustomer}
        </div>
        <div>
          <div className="flex gap-4 pb-3">
            <div className="w-6 h-4 md:w-8 md:h-6 lg:w-8 lg:h-8 bg-oppty-green-3 rounded-full flex items-center justify-center">
              <img src={correctIcon} alt="correctIcon" className="w-6 h-6 lg:w-8 lg:h-8" />
            </div>
            <div className="text-[8px] md:text-[14px] lg:text-[18px] font-raleway font-bold leading-normal text-oppty-black-6">
              {lang.clientAutoRunning}
            </div>
          </div>
          <div className="flex gap-4 pb-4 md:pb-8">
            <div className="w-6 h-4 md:w-8 md:h-6 lg:w-8 lg:h-8 bg-oppty-green-3 rounded-full flex items-center justify-center">
              <img src={correctIcon} alt="correctIcon" className="w-6 h-6 lg:w-8 lg:h-8" />
            </div>
            <div className="text-[8px] md:text-[14px] lg:text-[18px] font-raleway font-bold leading-normal text-oppty-black-6">
              {lang.clientSendingMessage}
            </div>
          </div>
          {/* <div className="flex gap-4 pb-4 md:pb-8">
            <div className="w-5 h-4 md:w-6 md:h-6 lg:w-8 lg:h-8 bg-oppty-green-3 rounded-full flex items-center justify-center">
              <img src={correctIcon} alt="correctIcon" className="w-6 h-6 lg:w-8 lg:h-8" />
            </div>
            <div className="text-[8px] md:text-[14px] lg:text-[18px] font-raleway font-bold leading-normal text-oppty-black-6">
              {lang.clientReporting}
            </div>
          </div> */}
          {/* <button className="border-[1px] border-oppty-black-6 rounded-[100px] text-xs md:text-md py-1 md:py-3 px-[14px] md:px-[24px]">
            {lang.generalLearnMore}
          </button> */}
        </div>
      </div>
      {/* <div className="bg-slate-300 w-[700px] h-[450px]"></div> */}
      <iframe
        title="OppTy Video"
        className="w-full h-72 md:h-[350px] lg:w-[700px] lg:h-[450px]"
        src={embedUrl}
        allowFullScreen
      ></iframe>
    </div>
  )
}

export default SeeHowPlatform
