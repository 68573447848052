import { useParams } from 'react-router-dom';
import Header from '../../common/header';
import Introduction from './introduction';
import OfficeTeam from './officeTeam';
import Benefits from './benefits';
import AvailableJobs from './availableJobs';
import Events from './events';
import Footer from '../../common/footer';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getEmployerById } from '../../actions/employerAction';

const Employer = () => {
  const dispatch = useDispatch();

  const { employer } = useParams();

  const employerData = useSelector((state) => state.selectedEmployer);

  useEffect(() => {
    dispatch(getEmployerById(employer));
  }, []);
  return (
    <div className="flex flex-col min-h-screen">
      <Header />
      <div className="flex flex-col justify-center items-center w-full">
        <div className="w-full max-w-[1200px] bg-white rounded-xl">
          <Introduction />
          {/* <Events /> */}
          {/* <OfficeTeam /> */}
          {employerData?.employer?.benefits?.length > 0 && <Benefits />}
          <AvailableJobs />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Employer;
