import { useDispatch, useSelector } from 'react-redux';
import benefitsIcon from '../../../utils/companyBenefits';
import { applyJob } from '../../../actions/jobAction';
import { ToastBar } from '../../../common/toastbar';
import { useEffect, useState } from 'react';
import { getLabelByValue } from '../../../utils/functions';
import { benefitsData } from '../../../constant/checkboxData';

const Benefits = () => {
  const dispatch = useDispatch();

  const [isApplied, setIsApplied] = useState(false);

  const lang = useSelector((state) => state.language.lang);
  const job = useSelector((state) => state.selectedJob);
  const user = useSelector((state) => state.user);

  const handleApplyJob = () => {
    if (user?.userId) {
      const childWindow = window.open('/apply-job', '_blank');

      if (childWindow) {
        childWindow.onload = () => {
          const message = { type: 'JobData', data: job?.job };
          childWindow.postMessage(message, window.location.origin);
        };
      } else {
        alert('Failed to open the new window');
      }
    } else {
      ToastBar.warning('You need to login to apply');
    }
  };

  useEffect(() => {
    const isApplied = job?.job?.applicants?.some((applicant) => applicant?.user === user?.userId);
    setIsApplied(isApplied);
  }, [job, user]);

  return (
    <div className="flex flex-col justify-center pb-10 px-5 lg:px-16">
      <div className="flex items-center gap-x-7 pb-10">
        <div className="text-4xl font-nunito font-bold text-black">{lang.companyBenefits}</div>
      </div>
      <div className="flex flex-wrap pb-20 gap-5">
        {job?.job?.employer?.benefits?.map((benefit) => {
          return (
            <div className="flex gap-x-6 w-96 items-center">
              <div className="h-12 w-12 bg-oppty-blue flex justify-center items-center rounded">
                {benefitsIcon(benefit.label)}
              </div>
              <div className="text-xl font-nunito font-normal text-black">{getLabelByValue(benefit.value, benefitsData)}</div>
            </div>
          );
        })}
      </div>
      <div className="flex justify-center items-center">
        <button
          type="button"
          className={`bg-black text-white shadow-lg text-lg font-nunito font-semibold leading-normal px-8 py-4 hover:bg-blue-gray-800 disabled:bg-black/60`}
          onClick={handleApplyJob}
          disabled={isApplied}
        >
          {isApplied ? lang.generalApplied : lang.generalApplyNow}
        </button>
      </div>
    </div>
  );
};

export default Benefits;
