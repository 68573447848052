import PortfolioCard from './portfolioCard';

const MyPortfolioSection = ({ user }) => {
  return (
    <div className="flex flex-col p-10 lg:p-16">
      <div className="text-[50px] font-bold leading-none">My Portfolio</div>
      <div className="flex flex-wrap w-full gap-5 justify-center mt-8">
        {user?.portfolios?.map((portfolio, index) => {
          return <PortfolioCard key={index} portfolio={portfolio} />;
        })}
      </div>
    </div>
  );
};

export default MyPortfolioSection;
