import React, { useState } from 'react';
import logo from '../../assets/images/headerLogo.svg';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { submitRegister } from '../../actions/userPreRegisterAction';
import { useNavigate, useParams } from 'react-router-dom';
import { createCandidateSchema } from '../../validations/validationSchemas';
import GoogleLoginProvider from './googleLogin';

const CreateCandidate = () => {
  const { referralId } = useParams();

  console.log('referralId: ', referralId)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const lang = useSelector((state) => state.language.lang);
  const [showPassword, setShowPassword] = useState(false);

  console.log(lang);
  const togglePasswordVisibility = (e) => {
    e.preventDefault(); // Prevent form submission when toggling visibility
    setShowPassword(!showPassword);
  };

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      password: '', 
      referralId: referralId || ''
    },
    validationSchema: createCandidateSchema,
    onSubmit: async (values) => {
      console.log('value: ', values);
      // dispatch(submitRegister(values)).then(navigate('/verifyOTP'))
      dispatch(submitRegister(values, navigate));

      //   try {
      //     await dispatch(submitRegister(values))
      //     navigate('/verifyOTP')
      //   } catch (error) {
      //     console.error('Registration failed:', error)
      //   }
    }
  });

  return (
    <div className="flex flex-col min-h-screen p-2 lg:p-0">
      <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 space-y-12">
        <div className="bg-white p-8 rounded-lg shadow-md w-full max-w-md">
          <div className="flex w-full justify-center">
            <img src={logo} alt="Logo" className="w-24 lg:w-[150px] lg:mt-[-10px] pb-5 cursor-pointer" onClick={() => navigate('/')}/>
          </div>
          <p className="text-left text-oppty-black font">{lang.createLets}</p>
          <h2 className="text-left text-2xl font-bold mb-6 text-oppty-black">{lang.createAccount}</h2>
          <form onSubmit={formik.handleSubmit}>
            <div className="mb-4">
              <label htmlFor="firstName" className="block text-sm font-medium text-gray-700">
                {lang.createFirstName}
              </label>
              <input
                type="text"
                id="firstName"
                name="firstName"
                placeholder={lang.createFirstName}
                className="w-full py-2 px-3 leading-tight border focus:outline rounded-md border-gray-300"
                onChange={formik.handleChange}
                value={formik.values.firstName}
              />
              {formik.touched.firstName && formik.errors.firstName ? (
                <div className="text-red-500 text-sm">{formik.errors.firstName}</div>
              ) : null}
            </div>
            <div className="mb-4">
              <label htmlFor="lastName" className="block text-sm font-medium text-gray-700">
                {lang.createLastName}
              </label>
              <input
                type="text"
                id="lastName"
                name="lastName"
                placeholder={lang.createLastName}
                className="w-full py-2 px-3 leading-tight border focus:outline rounded-md border-gray-300"
                onChange={formik.handleChange}
                value={formik.values.lastName}
              />
              {formik.touched.lastName && formik.errors.lastName ? (
                <div className="text-red-500 text-sm">{formik.errors.lastName}</div>
              ) : null}
            </div>
            <div className="mb-4">
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                {lang.createEmail}
              </label>
              <input
                type="text"
                id="email"
                name="email"
                placeholder={lang.createEmail}
                className="w-full py-2 px-3 leading-tight border focus:outline rounded-md border-gray-300"
                onChange={formik.handleChange}
                value={formik.values.email}
              />
              {formik.touched.email && formik.errors.email ? (
                <div className="text-red-500 text-sm">{formik.errors.email}</div>
              ) : null}
            </div>
            <div className="mb-6">
              <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                {lang.createPassword}
              </label>
              <div className="relative">
                <input
                  type={showPassword ? 'text' : 'password'}
                  id="password"
                  name="password"
                  placeholder={lang.createPassword}
                  className="w-full py-2 px-3 leading-tight border focus:outline rounded-md border-gray-300 pr-10"
                  onChange={formik.handleChange}
                  value={formik.values.password}
                />
                {formik.touched.password && formik.errors.password ? (
                  <div className="text-red-500 text-sm">{formik.errors.password}</div>
                ) : null}
                <div className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5">
                  <button
                    className="absolute inset-y-0 right-0 flex items-center px-4 text-gray-600"
                    onClick={togglePasswordVisibility}
                  >
                    {!showPassword ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-5 h-5"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
                        />
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-5 h-5"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                        />
                        <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                      </svg>
                    )}
                  </button>
                </div>
              </div>
              <p className="flex items-center gap-1 mt-2 font-sans text-xs italic antialiased font-normal leading-normal text-gray-700">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="w-4 h-4 -mt-px"
                >
                  <path
                    // fill-rule="evenodd"
                    d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm8.706-1.442c1.146-.573 2.437.463 2.126 1.706l-.709 2.836.042-.02a.75.75 0 01.67 1.34l-.04.022c-1.147.573-2.438-.463-2.127-1.706l.71-2.836-.042.02a.75.75 0 11-.671-1.34l.041-.022zM12 9a.75.75 0 100-1.5.75.75 0 000 1.5z"
                    // clip-rule="evenodd"
                  ></path>
                </svg>
                {lang.createUse}
              </p>
            </div>
            <button type="submit" className="w-full bg-black text-white p-2 rounded-md hover:bg-gray-700">
              {lang.createStart}
            </button>
            <div className="mt-4 flex items-center justify-between">
              <div className="flex-grow border-t border-gray-300"></div>
              <span className="text-sm text-oppty-black">{lang.createOr}</span>
              <div className="flex-grow border-t border-gray-300"></div>
            </div>
            <div className="py-3">
              <GoogleLoginProvider referralId={referralId} />
            </div>
            <div className="mt-4 flex justify-center items-center space-x-2">
              <p className="text-oppty-black font">{lang.createAlready}</p>
              <a href="/candidate-login" className="text-sm text-blue-600 hover:underline">
                {lang.createLoginHere}
              </a>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CreateCandidate;
