import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { useState } from 'react';
import { useFormik } from 'formik';
import { socialAdd, socialDelete } from '../../../actions/userAction';
import { UserGroupIcon } from '@heroicons/react/24/outline';
import { socialOptions } from '../../../constant/dropdownConstOptions';
import SocialTile from '../../../common/socialTile';
import { socialSchema } from '../../../validations/validationSchemas';

const SocialMedias = () => {
  const dispatch = useDispatch();

  const [addSocial, setAddSocial] = useState(false);

  const lang = useSelector((state) => state.language.lang);
  const user = useSelector((state) => state.user);

  const formik = useFormik({
    initialValues: {
      social: '',
      link: ''
    },
    validationSchema: socialSchema,
    onSubmit: async (values, { resetForm }) => {
      console.log('value: ', values);
      dispatch(socialAdd(values));
      resetForm();
      setAddSocial(false);
    }
  });

  const handleDeleteSocial = (index) => {
    const deleteSocial = user.socials[index];

    console.log('deleteSocial ', deleteSocial);
    dispatch(socialDelete(deleteSocial));
  };

  return (
    <div
      id="socials"
      className="flex flex-col justify-center py-5 lg:py-10 px-2 mx-2 lg:px-16 lg:mx-16 rounded-[20px] bg-white"
    >
      <div className="flex flex-row items-center gap-x-2 lg:gap-x-5">
        <div className="flex justify-center w-14 h-14 p-4 rounded-full bg-oppty-blue-5">
          <UserGroupIcon className="w-full h-full stroke-oppty-blue" />
        </div>
        <div className="text-lg lg:text-2xl font-raleway font-bold leading-normal uppercase  text-black pr-5">
          {lang.companySocials}
        </div>
        <div className="flex-grow border-t-[1px] border-oppty-grey-15"></div>
        {!addSocial && (
          <button
            onClick={() => setAddSocial(true)}
            className="text-base font-nunito font-bold leading-normal bg-black text-white px-6 py-1 rounded-md"
          >
            + {lang.profileAdd}
          </button>
        )}
      </div>
      <div className="flex flex-col justify-center py-3 pl-0 md:pl-16">
        <div className="flex flex-wrap gap-x-5 pb-7 gap-y-2">
          {user?.socials?.map((social, index) => {
            return (
              <SocialTile
                social={social.social}
                link={social.link}
                key={index}
                onDelete={() => handleDeleteSocial(index)}
              />
            );
          })}
        </div>
        {addSocial && (
          <form onSubmit={formik.handleSubmit}>
            <div>
              <div className="flex-grow border-t-[1px] pt-3 border-oppty-grey-15"></div>
              <div className="flex flex-row gap-x-10 pt-5">
                <div className="w-1/2 flex flex-col gap-y-2">
                  <div className="text-base font-nunito font-bold leading-normal text-oppty-grey-16">
                    {lang.profileSelectSocial}
                  </div>
                  <Select
                    className="basic-single"
                    classNamePrefix="select"
                    defaultValue={'Select Proficiency'}
                    value={socialOptions.find((option) => option.value === formik.values.level)}
                    onChange={(selectedOption) => formik.setFieldValue('social', selectedOption?.value || '')}
                    placeholder={lang.profileSelect}
                    isDisabled={false}
                    isLoading={false}
                    isClearable={false}
                    isRtl={false}
                    isSearchable={false}
                    name="social"
                    options={socialOptions}
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        height: 54
                      }),
                      input: (provided) => ({
                        ...provided,
                        boxShadow: 'none',
                        '& input': {
                          boxShadow: 'none !important',
                          outline: 'none !important'
                        }
                      })
                    }}
                  />
                  {formik.touched.social && formik.errors.social ? (
                    <div className="text-red-500 text-sm">{formik.errors.social}</div>
                  ) : null}
                </div>
                <div className="w-1/2 flex flex-col gap-y-2">
                  <div className="text-base font-nunito font-bold leading-normal text-oppty-grey-16">
                    {lang.generalLink}
                  </div>
                  <input
                    type="text"
                    id="link"
                    name="link"
                    onChange={formik.handleChange}
                    value={formik.values.link}
                    className="w-full py-4 px-5 leading-tight border focus:outline rounded-md border-gray-300"
                  />
                  {formik.touched.link && formik.errors.link ? (
                    <div className="text-red-500 text-sm">{formik.errors.link}</div>
                  ) : null}
                </div>
              </div>
              <div className="flex justify-end flex-row gap-x-2 pt-10 pb-5">
                <button
                  onClick={() => setAddSocial(false)}
                  className="text-base font-nunito font-semibold leading-normal bg-oppty-grey-14 text-oppty-grey-13 px-8 py-3 rounded-md"
                >
                  {lang.profileCancel}
                </button>
                <button
                  type="submit"
                  className="text-base font-nunito font-semibold leading-normal bg-oppty-blue text-white px-8 py-3 rounded-md"
                >
                  {lang.profileAdd}
                </button>
              </div>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default SocialMedias;
