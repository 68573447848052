import axios from 'axios';
import { ToastBar } from '../common/toastbar';
import { getCurrentUser } from './userAction';

const apiUrl = process.env.REACT_APP_APIURL;
const localLang = localStorage.getItem('lan');

export const FETCH_ALL_PSYCHOMETRIC_QUESTIONS = 'FETCH_ALL_PSYCHOMETRIC_QUESTIONS';

export const fetchAllQuestions = (data) => ({
  type: FETCH_ALL_PSYCHOMETRIC_QUESTIONS,
  payload: data
});

export const getAllPsychometricsQuestions = () => {
  return async (dispatch) => {
    try {
      const response = await axios.get(`${apiUrl}/psychometric/get-psychometrics`, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });

      if (response.status === 200) {
        console.log('ques res: ', response.data)
        dispatch(fetchAllQuestions(response.data));
        return response.data;
      }
    } catch (error) {
      // Handle error response
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[localLang]);
      } else {
        // Handle other errors
        console.log(error.message);
        ToastBar.error(error.message);
      }
      return { error: error.message };
    }
  };
};

export const submitPsychometricTest = (inputData, navigate) => {
  console.log('input value: ', inputData);
  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/psychometric/submit-psychometric-test`, inputData, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });

      if (response.status === 200) {
        console.log(response.data);
        dispatch(getCurrentUser());
        navigate('/profile');
        ToastBar.success(response.data.successMsg[localLang]);
      }
    } catch (error) {
      // Handle error response
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[localLang]);
      } else {
        // Handle other errors
        console.log(error.message);
        ToastBar.error(error.message);
      }
    }
  };
};

export const updateDisplay = (inputData) => {
  console.log('input value: ', inputData);
  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiUrl}/psychometric/update-show-psychometric`, inputData, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      });

      if (response.status === 200) {
        console.log(response.data);
        dispatch(getCurrentUser());
        ToastBar.success(response.data.successMsg[localLang]);
      }
    } catch (error) {
      // Handle error response
      if (error.response && error.response.status === 500) {
        console.log(error.response.data.errorMsg);
        ToastBar.error(error.response.data.errorMsg[localLang]);
      } else {
        // Handle other errors
        console.log(error.message);
        ToastBar.error(error.message);
      }
    }
  };
};