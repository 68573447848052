import PortfolioCard from './portfolioCard';

const Portfolio = ({ user }) => {
  return (
    <div className="w-full flex flex-col p-10 lg:p-16 shadow-md bg-white">
      <div className="text-4xl lg:text-5xl font-bold lg:w-1/2 mb-10">My Portfolio</div>
      <div className="flex flex-wrap w-full gap-8 justify-center">
        {user?.portfolios?.map((portfolio, index) => {
          return <PortfolioCard key={index} portfolio={portfolio} />;
        })}
      </div>
    </div>
  );
};

export default Portfolio;
