import { Carousel } from '@material-tailwind/react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import CoverPageArrangement from '../../../common/coverPageArrangement';

const CompanyDetails = () => {
  const [showModal, setShowModal] = useState(false);

  const lang = useSelector((state) => state.language.lang);
  const job = useSelector((state) => state.selectedJob);

  return (
    <div className="flex flex-col justify-center pb-10 px-5 lg:px-16">
      <CoverPageArrangement employer={job?.job} setShowModal={setShowModal} />
      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="flex flex-col items-end w-[1000px] h-[500px]">
              <button
                className="text-white bg-red-500 rounded font-extrabold px-2 py-1 text-center text-sm mr-1 mb-1 transition-all duration-150"
                type="button"
                onClick={() => setShowModal(false)}
              >
                x
              </button>
              <Carousel className="rounded-xl">
                {job?.job?.employer?.images?.map((image) => (
                  <div className="w-full h-full bg-black justify-center flex">
                    <img src={image} alt="slider" className="h-full w-fit" />
                  </div>
                ))}
              </Carousel>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
      <div className="w-full flex flex-wrap border-b-[1px]">
        <div className="w-full lg:w-3/4">
          <div className="pb-10">
            <div className="text-4xl font-nunito font-bold text-black pb-8">{lang.generalAboutCompany}</div>
            <div
              className="font-nunito text-black"
              dangerouslySetInnerHTML={{ __html: job?.job?.employer?.companyDescription }}
            ></div>
          </div>
        </div>
        <div className="w-fit lg:w-1/4 flex justify-end items-start gap-x-5">
          <button
            type="button"
            onClick={() => window.open(`/employers/${job?.job?.employer?._id}`, '_blank')}
            className="py-3 px-10 bg-black rounded text-white text-sm font-bold hover:bg-black/80"
          >
            {lang.companyViewProfile}
          </button>
        </div>
      </div>
    </div>
  );
};

export default CompanyDetails;
