import ReactPlayer from 'react-player';

const Video = ({ user }) => {
  return (
    <div className="w-full flex flex-col p-10 lg:p-16 bg-template9-gray-2">
      <div className="text-5xl font-bold text-white mb-16">My Video</div>
      {/* <div className="text-xl mb-10 text-white">Check out my video</div> */}
      <div className="flex flex-col w-full">
        <div className="bg-template9-gray-1 w-full lg:w-[550px] border-template9-orange-1 border-2">
          <ReactPlayer url={user?.video?.videoUrl} controls={true} width="100%" />
        </div>
      </div>
    </div>
  );
};

export default Video;
