import { useDispatch, useSelector } from 'react-redux';
import profilePerson from '../../../assets/icons/profilePerson.svg';
import profileEdit from '../../../assets/icons/pencil.svg';
import DefaultPic from '../../../assets/images/Default_profilepic.png';
import CountryCodeDropdown from '../../../components/countryCodeDropdown';
import { useState } from 'react';
import { useFormik } from 'formik';
import { generalDetailAdd } from '../../../actions/userAction';
import { DevicePhoneMobileIcon, EnvelopeIcon, DocumentTextIcon, HomeIcon } from '@heroicons/react/24/outline';
import Select from 'react-select';
import ProfileImageUploadModal from '../../../common/profileImageUploadModal';
import { Avatar } from '@material-tailwind/react';
import AsyncSelect from 'react-select/async';
import countryOptions from '../../../constant/countries.json';
import industryOptions from '../../../constant/all_industries.json';
import { salutationOptions } from '../../../constant/dropdownConstOptions';
import { generalDetailsSchema, stripHtmlTags } from '../../../validations/validationSchemas';
import ReactQuill from 'react-quill';

const GeneralDetails = () => {
  const dispatch = useDispatch();

  const [editGeneral, setEditGeneral] = useState(false);
  const [editProfilePicModal, setEditProfilePicModal] = useState(false);

  const lang = useSelector((state) => state.language.lang);
  const user = useSelector((state) => state.user);

  const [aboutMeText, setAboutMe] = useState(user?.aboutMe);

  const handleAboutMeLimit = (value) => {
    const plainTextDescription = stripHtmlTags(aboutMeText);

    const inputValue = value;
    setAboutMe(inputValue);
    formik.setValues({
      ...formik.values,
      aboutMe: plainTextDescription
    });
  };

  const handleAreaCodeSelect = (areaCode) => {
    console.log('areaCode: ', areaCode);
    formik.setValues({
      ...formik.values,
      areaCode: areaCode
    });
  };

  const handleEditClick = () => {
    setEditGeneral(true);
  };

  const handleCancel = () => {
    setEditGeneral(false);
    formik.setValues(initialValues);
  };

  const plainTextDescription = stripHtmlTags(aboutMeText);
  const descriptionLength = plainTextDescription.length;

  const initialValues = {
    firstName: user?.firstName || '',
    lastName: user?.lastName || '',
    salutation: user?.salutation || '',
    email: user?.email || '',
    areaCode: user?.areaCode || '65',
    mobile: user?.mobile || '',
    // availability: user?.availability || false,
    address: user?.address || '',
    state: user?.state || '',
    city: user?.city || '',
    country: user?.country || '',
    postal: user?.postal || '',
    aboutMe: user?.aboutMe || '',
    title: user?.title || '',
    industry: user?.industry || ''
  };

  const formik = useFormik({
    initialValues,
    validationSchema: generalDetailsSchema,
    onSubmit: async (values) => {
      values.aboutMe = aboutMeText
      console.log('value: ', values);
      const result = await dispatch(generalDetailAdd(values));
      if (result && !result.error) {
        setEditGeneral(false);
      }
    }
  });

  const handleAvailableWork = (event) => {
    const value = {
      availability: event.target.checked
    };

    dispatch(generalDetailAdd(value));
  };

  const countryLoadOptions = (inputValue, callback) => {
    const filteredOptions = countryOptions
      .filter((option) => option.label.toLowerCase().includes(inputValue.toLowerCase()))
      .slice(0, 20);

    callback(filteredOptions);
  };

  const industryLoadOptions = (inputValue, callback) => {
    const filteredOptions = industryOptions.filter((option) =>
      option.label.toLowerCase().includes(inputValue.toLowerCase())
    );

    callback(filteredOptions);
  };

  console.log('formik.aboutMe.length: ', formik.values.aboutMe.length);
  return (
    <div
      id="generalDetails"
      className="flex flex-col justify-center py-10 px-2 mx-2 lg:px-16 lg:mx-16 rounded-[20px] bg-white "
    >
      <form onSubmit={formik.handleSubmit}>
        <div className="flex justify-between flex-wrap lg:flex-nowrap items-center gap-y-5 mb-5 md:mb-0">
          <div className="flex flex-row gap-x-5">
            <div className="flex justify-center w-14 h-14 p-4 rounded-full bg-oppty-blue-5">
              <img src={profilePerson} alt="profile icon" className="h-full w-full" />
            </div>
            <div>
              <div className="text-lg lg:text-2xl font-raleway font-bold uppercase leading-normal text-black pb-[1px]">
                {lang.profileEditProfile}
              </div>
              <div className="text-lg font-raleway font-thin leading-normal text-black">{lang.profileAddAll}</div>
            </div>
          </div>

          <div className="flex flex-row gap-x-8 items-center ml-[74px]">
            <div className="text-lg font-nunito font-semibold leading-normal text-oppty-grey-12">
              {lang.profileAvailableToWork}
            </div>

            <div className="inline-flex items-center">
              <div className="relative inline-block w-8 h-4 rounded-full cursor-pointer">
                <input
                  id="availability"
                  name="availability"
                  type="checkbox"
                  checked={user?.availability}
                  onChange={handleAvailableWork}
                  className="absolute w-8 h-4 transition-colors duration-300 rounded-full appearance-none cursor-pointer peer bg-blue-gray-100 checked:bg-oppty-purple-1 peer-checked:border-oppty-purple-1 peer-checked:before:bg-oppty-purple-1"
                />
                <label
                  htmlFor="availability"
                  className="before:content[''] absolute top-2/4 -left-1 h-6 w-6 -translate-y-2/4 cursor-pointer rounded-full border border-blue-gray-100 bg-white checked:bg-oppty-blue peer:bg-oppty-blue peer-checked:bg-oppty-blue shadow-md transition-all duration-300 before:absolute before:top-2/4 before:left-2/4 before:block before:h-10 before:w-10 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-oppty-blue before:opacity-0 before:transition-opacity hover:before:opacity-10 peer-checked:translate-x-full peer-checked:border-oppty-blue peer-checked:before:bg-oppty-blue"
                >
                  <div
                    className="inline-block p-5 rounded-full top-2/4 left-2/4 -translate-x-2/4 -translate-y-2/4"
                    data-ripple-dark="true"
                  ></div>
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col justify-center md:py-10 pl-0 md:pl-16">
          <div className="flex flex-row items-center">
            <div className="text-lg font-nunito font-bold leading-normal uppercase  text-black pr-5">
              {lang.employeeHomeTabGeneral}
            </div>
            <div className="flex-grow border-t-[1px] border-oppty-grey-15"></div>
          </div>
          {editGeneral ? (
            <div>
              <div className="flex flex-col py-7 gap-y-5 lg:gap-y-10">
                <div className="flex flex-col lg:flex-row gap-x-10 gap-y-5">
                  <div className="w-full lg:w-1/2 flex flex-col gap-y-2">
                    <div className="text-base font-nunito font-bold leading-normal text-oppty-grey-16">
                      {lang.createFirstName}
                    </div>
                    <input
                      type="text"
                      id="firstName"
                      name="firstName"
                      onChange={formik.handleChange}
                      value={formik.values.firstName}
                      className="py-4 px-5 leading-tight border focus:outline rounded-md border-gray-300"
                    />
                    {formik.touched.firstName && formik.errors.firstName ? (
                      <div className="text-red-500 text-base">{formik.errors.firstName}</div>
                    ) : null}
                  </div>
                  <div className="w-full lg:w-1/2 flex flex-col gap-y-2">
                    <div className="text-base font-nunito font-bold leading-normal text-oppty-grey-16">
                      {lang.createLastName}
                    </div>
                    <input
                      type="text"
                      id="lastName"
                      name="lastName"
                      onChange={formik.handleChange}
                      value={formik.values.lastName}
                      className="py-4 px-5 leading-tight border focus:outline rounded-md border-gray-300"
                    />
                    {formik.touched.lastName && formik.errors.lastName ? (
                      <div className="text-red-500 text-base">{formik.errors.lastName}</div>
                    ) : null}
                  </div>
                </div>
                <div className="flex flex-col lg:flex-row gap-x-10 gap-y-5">
                  <div className="w-full lg:w-1/3 flex flex-col gap-y-2">
                    <div className="text-base font-nunito font-bold leading-normal text-oppty-grey-16">
                      {lang.generalSalutation}
                    </div>
                    <div className="flex w-full">
                      <Select
                        className="basic-single w-full"
                        classNamePrefix="select"
                        defaultValue={'Select Salutation'}
                        value={salutationOptions.find((option) => option.value === formik.values.salutation)}
                        onChange={(selectedOption) => formik.setFieldValue('salutation', selectedOption?.value || '')}
                        isDisabled={false}
                        isLoading={false}
                        isClearable={false}
                        isRtl={false}
                        isSearchable={false}
                        name="salutation"
                        options={salutationOptions}
                        styles={{
                          control: (provided) => ({
                            ...provided,
                            height: 54
                          })
                        }}
                      />
                    </div>
                    {formik.touched.salutation && formik.errors.salutation ? (
                      <div className="text-red-500 text-base">{formik.errors.salutation}</div>
                    ) : null}
                  </div>
                  <div className="w-full lg:w-1/3 flex flex-col gap-y-2">
                    <div className="text-base font-nunito font-bold leading-normal text-oppty-grey-16">
                      {lang.generalContactNumber}
                    </div>
                    <div className="flex w-full">
                      <CountryCodeDropdown onSelect={handleAreaCodeSelect} value={formik.values.areaCode} />
                      <input
                        type="text"
                        id="mobile"
                        name="mobile"
                        onChange={formik.handleChange}
                        value={formik.values.mobile}
                        className="w-full py-4 px-5 leading-tight border focus:outline rounded-r-md border-gray-300"
                      />
                    </div>
                    {formik.touched.areaCode && formik.errors.areaCode ? (
                      <div className="text-red-500 text-base">{formik.errors.areaCode}</div>
                    ) : null}{' '}
                    {formik.touched.mobile && formik.errors.mobile ? (
                      <div className="text-red-500 text-base">{formik.errors.mobile}</div>
                    ) : null}
                  </div>
                  <div className="w-full lg:w-1/3 flex flex-col gap-y-2">
                    <div className="text-base font-nunito font-bold leading-normal text-oppty-grey-16">
                      {lang.createEmail}
                    </div>
                    <input
                      type="text"
                      id="email"
                      name="email"
                      onChange={formik.handleChange}
                      value={formik.values.email}
                      disabled
                      className="py-4 px-5 leading-tight border focus:outline rounded-md border-gray-300 disabled:bg-gray-200"
                    />
                    {formik.touched.email && formik.errors.email ? (
                      <div className="text-red-500 text-base">{formik.errors.email}</div>
                    ) : null}
                  </div>
                </div>
                <div className="flex flex-row gap-x-10">
                  <div className="w-full flex flex-col gap-y-2">
                    <div className="text-base font-nunito font-bold leading-normal text-oppty-grey-16">
                      {lang.profileAddress}
                    </div>
                    <input
                      type="text"
                      id="address"
                      name="address"
                      onChange={formik.handleChange}
                      value={formik.values.address}
                      className="py-4 px-5 leading-tight border focus:outline rounded-md border-gray-300"
                    />
                    {formik.touched.address && formik.errors.address ? (
                      <div className="text-red-500 text-base">{formik.errors.address}</div>
                    ) : null}
                  </div>
                </div>
                <div className="flex flex-col lg:flex-row gap-x-10 gap-y-5">
                  <div className="w-full lg:w-1/2 flex flex-col gap-y-2">
                    <div className="text-base font-nunito font-bold leading-normal text-oppty-grey-16">
                      {lang.profileCity}
                    </div>
                    <input
                      type="text"
                      id="city"
                      name="city"
                      onChange={formik.handleChange}
                      value={formik.values.city}
                      className="py-4 px-5 leading-tight border focus:outline rounded-md border-gray-300"
                    />
                    {formik.touched.city && formik.errors.city ? (
                      <div className="text-red-500 text-base">{formik.errors.city}</div>
                    ) : null}
                  </div>
                  <div className="w-full lg:w-1/2 flex flex-col gap-y-2">
                    <div className="text-base font-nunito font-bold leading-normal text-oppty-grey-16">
                      {lang.profileState}
                    </div>
                    <input
                      type="text"
                      id="state"
                      name="state"
                      onChange={formik.handleChange}
                      value={formik.values.state}
                      className="py-4 px-5 leading-tight border focus:outline rounded-md border-gray-300"
                    />
                    {formik.touched.state && formik.errors.state ? (
                      <div className="text-red-500 text-base">{formik.errors.state}</div>
                    ) : null}
                  </div>
                </div>
                <div className="flex flex-col lg:flex-row gap-x-10 gap-y-5">
                  <div className="w-full lg:w-1/2 flex flex-col gap-y-2">
                    <div className="text-base font-nunito font-bold leading-normal text-oppty-grey-16">
                      {lang.profileCountry}
                    </div>
                    <AsyncSelect
                      cacheOptions
                      defaultOptions
                      value={countryOptions.find((option) => option.value === formik.values.country)}
                      onChange={(selectedOption) => {
                        const selectedValue = selectedOption || '';
                        if (selectedValue) formik.setFieldValue('country', selectedValue.value);
                      }}
                      loadOptions={countryLoadOptions}
                      isDisabled={false}
                      isClearable={true}
                      isSearchable={true}
                      name="countries"
                      id="countries"
                      menuPlacement="bottom"
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          height: 54
                        }),
                        input: (provided) => ({
                          ...provided,
                          boxShadow: 'none',
                          '& input': {
                            boxShadow: 'none !important',
                            outline: 'none !important'
                          }
                        })
                      }}
                      placeholder="Select Country"
                      className="w-full"
                    />
                    {formik.touched.country && formik.errors.country ? (
                      <div className="text-red-500 text-base">{formik.errors.country}</div>
                    ) : null}
                  </div>
                  <div className="w-full lg:w-1/2 flex flex-col gap-y-2">
                    <div className="text-base font-nunito font-bold leading-normal text-oppty-grey-16">
                      {lang.profilePostalCode}
                    </div>
                    <input
                      type="text"
                      id="postal"
                      name="postal"
                      onChange={formik.handleChange}
                      value={formik.values.postal}
                      className="py-4 px-5 leading-tight border focus:outline rounded-md border-gray-300"
                    />
                    {formik.touched.postal && formik.errors.postal ? (
                      <div className="text-red-500 text-base">{formik.errors.postal}</div>
                    ) : null}
                  </div>
                </div>
                <div className="flex flex-col lg:flex-row gap-x-10 md:gap-y-5">
                  <div className="w-full lg:w-1/2 flex flex-col gap-y-2">
                    <div className="text-base font-nunito font-bold leading-normal text-oppty-grey-16">
                      {lang.profileIndustry}
                    </div>
                    <AsyncSelect
                      cacheOptions
                      defaultOptions
                      value={industryOptions.find((option) => option.value === formik.values.industry)}
                      onChange={(selectedOption) => {
                        const selectedValue = selectedOption || '';
                        if (selectedValue) formik.setFieldValue('industry', selectedValue.value);
                      }}
                      loadOptions={industryLoadOptions}
                      isDisabled={false}
                      isClearable={true}
                      isSearchable={true}
                      name="countries"
                      id="countries"
                      menuPlacement="bottom"
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          height: 54
                        }),
                        input: (provided) => ({
                          ...provided,
                          boxShadow: 'none',
                          '& input': {
                            boxShadow: 'none !important',
                            outline: 'none !important'
                          }
                        })
                      }}
                      placeholder="Select Industry"
                      className="w-full"
                    />
                    {formik.touched.industry && formik.errors.industry ? (
                      <div className="text-red-500 text-base">{formik.errors.industry}</div>
                    ) : null}
                  </div>
                  <div className="w-1/2 flex flex-col gap-y-2"></div>
                </div>
              </div>
              <div className="flex flex-row items-center pb-2 md:pb-8">
                <div className="text-lg font-nunito font-bold leading-normal uppercase text-black pr-5">
                  {lang.profileTitle}
                </div>
                <div className="flex-grow border-t-[1px] border-oppty-grey-15"></div>
              </div>
              <div className="w-full flex flex-col">
                <input
                  type="text"
                  id="title"
                  name="title"
                  onChange={formik.handleChange}
                  value={formik.values.title}
                  className="py-4 px-5 w-full leading-tight border focus:outline rounded-md border-gray-300"
                />
                <p className={`text-xs ${formik.values.title?.length > 1000 ? 'text-red-500' : ''}`}>
                  {formik.values.title?.length}/{100}
                </p>
                {formik.touched.title && formik.errors.title ? (
                  <div className="text-red-500 text-base">{formik.errors.title}</div>
                ) : null}
              </div>
              <div className="flex flex-row items-center py-5 md:py-10">
                <div className="text-lg font-nunito font-bold leading-normal uppercase text-black pr-5">
                  {lang.generalAboutMe}
                </div>
                <div className="flex-grow border-t-[1px] border-oppty-grey-15"></div>
              </div>
              <div className="flex flex-col gap-x-10">
                <ReactQuill theme="snow" value={aboutMeText} onChange={handleAboutMeLimit} className="mb-1" />
                <p className={`mt-1 text-xs ${descriptionLength > 1000 ? 'text-red-500' : ''}`}>
                  {descriptionLength}/{1000}
                </p>
                {formik.touched.aboutMe && formik.errors.aboutMe ? (
                  <div className="text-red-500 text-base">{formik.errors.aboutMe}</div>
                ) : null}
              </div>
              <div className="flex w-full gap-x-4 pt-5 justify-end">
                <button
                  onClick={handleCancel}
                  className="text-lg font-nunito font-semibold leading-normal bg-oppty-grey-14 text-oppty-grey-13 px-8 py-3 rounded-md"
                >
                  {lang.profileCancel}
                </button>
                <button
                  type="submit"
                  className="text-lg font-nunito font-semibold leading-normal bg-oppty-blue text-white px-8 py-3 rounded-md"
                >
                  {lang.profileSave}
                </button>
              </div>
            </div>
          ) : (
            <div className="relative border rounded-md border-gray-300 bg-oppty-grey-17 p-5 mt-5 flex flex-wrap-reverse md:flex-nowrap justify-center md:justify-between">
              <div>
                <div className="text-2xl lg:text-3xl font-semibold">
                  {user?.firstName} {user?.lastName}
                </div>
                <div className="text-lg font-semibold">{user?.title}</div>
                <div className="text-base font-medium mb-7 text-gray-500">{user?.industry}</div>
                {user?.areaCode && user?.mobile && (
                  <div className="flex gap-x-2 items-center mb-4">
                    <div>
                      <DevicePhoneMobileIcon className="h-6 w-6" />
                    </div>
                    <div className="text-lg font-medium">
                      +{user?.areaCode}
                      {user?.mobile}
                    </div>
                  </div>
                )}
                <div className="flex gap-x-2 items-center mb-4">
                  <div>
                    <EnvelopeIcon className="h-6 w-6" />
                  </div>
                  <div className="text-lg font-medium">{user?.email}</div>
                </div>
                <div className="flex gap-x-2 items-start mb-8">
                  <div>
                    <HomeIcon className="h-6 w-6" />
                  </div>
                  <div>
                    <div className="text-lg font-medium">{user?.address}</div>
                    <div className="text-lg font-medium">
                      {user?.city} {user?.state} {user?.country} {user?.postal}
                    </div>
                  </div>
                </div>
                <div className="flex items-start">
                  <div className="flex-shrink-0 h-6 w-6">
                    <DocumentTextIcon className="h-full w-full" />
                  </div>
                  <div className="ml-2 text-lg" dangerouslySetInnerHTML={{ __html: aboutMeText }}></div>
                </div>
              </div>
              <div className="md:absolute top-5 right-5 flex gap-x-4 mb-5 md:mb-0">
                <div className="h-40 w-40 relative flex flex-col justify-end items-center rounded-full">
                  <Avatar src={user?.avatar || DefaultPic} alt="profile_pic" className="w-full h-full rounded-full" />
                  <button
                    type="button"
                    onClick={() => setEditProfilePicModal(true)}
                    className="absolute text-xs text-white rounded-md font-semibold px-1 bg-deep-purple-500 hover:bg-deep-purple-700"
                  >
                    {lang.profileEdit}
                  </button>
                </div>
                <div>
                  <button
                    type="button"
                    onClick={handleEditClick}
                    className="flex justify-center w-8 h-8 p-2 rounded-md hover:bg-green-400"
                  >
                    <img src={profileEdit} alt="profile icon" className="h-full w-full" />
                  </button>
                </div>
              </div>
              {editProfilePicModal && (
                <ProfileImageUploadModal closeModal={() => setEditProfilePicModal(false)} imageType="profile" />
              )}
            </div>
          )}
        </div>
      </form>
    </div>
  );
};

export default GeneralDetails;
