import { ReactComponent as Pinterest } from '../assets/icons/icons8-pinterest.svg';
import { ReactComponent as Linkedin } from '../assets/icons/icons8-linkedin.svg';
import { ReactComponent as Github } from '../assets/icons/icons8-github.svg';
import { ReactComponent as Facebook } from '../assets/icons/icons8-facebook.svg';
import { ReactComponent as Instagram } from '../assets/icons/icons8-insta.svg';
import { ReactComponent as TwitterX } from '../assets/icons/icons8-twitterx.svg';
import { ReactComponent as Youtube } from '../assets/icons/icons8-youtube.svg';

const SocialTile = ({ social, link, onDelete }) => {
  return (
    <div className="flex px-4 lg:px-8 py-2 bg-oppty-grey-17 rounded-md gap-x-3 items-center w-fit">
      <div className="w-10 h-10">
        {social === 'Linkedin' && <Linkedin className="fill-blue-800 w-full h-full" />}
        {social === 'Github' && <Github className="fill-black w-full h-full" />}
        {social === 'Pinterest' && <Pinterest className="fill-red-800 w-full h-full" />}
        {social === 'Facebook' && <Facebook className="fill-blue-800 w-full h-full" />}
        {social === 'Instagram' && <Instagram className="fill-red-800 w-full h-full" />}
        {social === 'X' && <TwitterX className="fill-black w-full h-full" />}
        {social === 'Youtube' && <Youtube className="fill-black w-full h-full" />}
      </div>
      <div
        style={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis'
        }}
        className='max-w-60 lg:max-w-72'
        title={link}
      >
        {link}
      </div>
      {onDelete && (
        <button type="button" onClick={onDelete} className="text-sm font-bold hover:bg-red-500 px-1 flex-shrink-0">
          X
        </button>
      )}
    </div>
  );
};

export default SocialTile;
