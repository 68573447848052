import ReactPlayer from 'react-player';

const Video = ({ user }) => {
  return (
    <div className="w-full flex flex-col p-10 lg:p-16 shadow-md bg-white">
      <div className="text-5xl font-bold text-template6-purple-1">Video</div>
      <div className="text-xl mb-10 text-template6-purple-1">Check out my video</div>
      <div className="flex flex-col pt-5 w-full">
        <div className="bg-template6-purple-1/20 w-full lg:w-[550px] border-template5-blue-1 border-2">
          <ReactPlayer url={user?.video?.videoUrl} controls={true} width="100%" />
        </div>
      </div>
    </div>
  );
};

export default Video;
