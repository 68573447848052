import WorkExperienceCard from './workExperienceCard';
import bgImage from '../../../assets/templates/template7/template7bg2.jpeg';

const WorkExperience = ({ user }) => {
  return (
    <div className="w-full flex flex-col shadow-md bg-white">
      <div className="flex flex-col lg:flex-row">
        <div className="hidden lg:flex flex-col w-full lg:w-1/2">
          <div className="flex-grow flex items-start">
            <img src={bgImage} alt="bgImage" className="h-[650px] w-full object-cover bg-template6-purple-1 p-16" />
          </div>
        </div>
        <div className="flex flex-col w-full lg:w-1/2 gap-y-5 p-10 lg:p-16">
          <div className="text-5xl font-bold mb-5 text-template6-purple-1">Work Experience</div>
          {user?.workExperiences?.map((work, index) => {
            return <WorkExperienceCard key={index} work={work} />;
          })}
        </div>
      </div>
    </div>
  );
};

export default WorkExperience;
