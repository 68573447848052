import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { useState } from 'react';
import { monthOptions, yearOptions } from '../../../constant/dropdownConstOptions';
import { Checkbox } from '@material-tailwind/react';
import { useFormik } from 'formik';
import { certificationAdd, certificationDelete, certificationEdit } from '../../../actions/userAction';
import { BookOpenIcon } from '@heroicons/react/24/outline';
import ProfileCertificateCard from '../../../common/profileCertificateCard';
import { candidateEducationSchema, validateDates } from '../../../validations/validationSchemas';
import countryOptions from '../../../constant/countries.json';
import AsyncSelect from 'react-select/async';
import ReactQuill from 'react-quill';

const Certifications = () => {
  const dispatch = useDispatch();

  const [addCertificate, setAddCertificate] = useState(false);
  const [current, setCurrent] = useState(false);

  const lang = useSelector((state) => state.language.lang);
  const user = useSelector((state) => state.user);

  const handleCurrentChange = () => {
    setCurrent(!current);
    if (!current) {
      formik.setValues({
        ...formik.values,
        endMonth: '99',
        endYear: '99'
      });
    }
  };

  const formik = useFormik({
    initialValues: {
      institution: '',
      qualification: '',
      course: '',
      startMonth: '',
      startYear: '',
      endMonth: '',
      endYear: '',
      country: '',
      description: ''
    },
    validationSchema: candidateEducationSchema,
    validate: validateDates,
    onSubmit: async (values, { resetForm }) => {
      console.log('value: ', values);
      const result = await dispatch(certificationAdd(values));
      if (result && !result.error) {
        resetForm();
        setAddCertificate(false);
        setCurrent(false);
      }
    }
  });

  const handleDeleteCertificate = (index) => {
    const deleteCertification = user.certifications[index];

    console.log('deleteCertifications: ', deleteCertification);
    dispatch(certificationDelete(deleteCertification));
  };

  const handleEditCertificate = (editedCertificate) => {
    return dispatch(certificationEdit(editedCertificate))
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.error('API call failed:', error);
        throw error;
      });
  };

  const countryLoadOptions = (inputValue, callback) => {
    const filteredOptions = countryOptions
      .filter((option) => option.label.toLowerCase().includes(inputValue.toLowerCase()))
      .slice(0, 20);

    callback(filteredOptions);
  };

  return (
    <div
      id="certification"
      className="flex flex-col justify-center py-5 lg:py-10 px-2 mx-2 lg:px-16 lg:mx-16 rounded-[20px] bg-white"
    >
      <div className="flex flex-row items-center gap-x-2 lg:gap-x-5">
        <div className="flex justify-center w-14 h-14 p-4 rounded-full bg-oppty-blue-5">
          <BookOpenIcon className="h-full w-full stroke-oppty-blue" />
        </div>
        <div className="text-lg lg:text-2xl font-raleway font-bold leading-normal uppercase  text-black pr-5">
          {lang.profileCertification}
        </div>
        <div className="flex-grow border-t-[1px] border-oppty-grey-15"></div>
        {!addCertificate && (
          <button
            onClick={() => setAddCertificate(true)}
            className="text-base font-nunito font-bold leading-normal bg-black text-white px-6 py-1 rounded-md"
          >
            + {lang.profileAdd}
          </button>
        )}
      </div>
      <div className="flex flex-col justify-center py-3 pl-0 md:pl-16">
        <div className="flex flex-col gap-x-5 gap-y-2">
          {user?.certifications?.map((certification, index) => {
            return (
              <ProfileCertificateCard
                certification={certification}
                uniqueKey={index}
                key={index}
                onDelete={() => handleDeleteCertificate(index)}
                onEdit={handleEditCertificate}
              />
            );
          })}
        </div>
        {addCertificate && (
          <form onSubmit={formik.handleSubmit}>
            <div className="flex flex-col">
              <div className="flex flex-row items-center py-10">
                <div className="text-lg font-nunito font-bold leading-normal uppercase text-black pr-5">
                  {lang.profileAddNewCertification}
                </div>
                <div className="flex-grow border-t-[1px] border-oppty-grey-15"></div>
              </div>
              <div className="flex flex-col gap-y-8">
                <div className="flex flex-col lg:flex-row gap-x-10 gap-y-5">
                  <div className="w-full lg:w-1/2 flex flex-col gap-y-2">
                    <div className="text-base font-nunito font-bold leading-normal text-oppty-grey-16">
                      {lang.profileInstitution}
                    </div>
                    <div>
                      <input
                        type="text"
                        id="institution"
                        name="institution"
                        value={formik.values.institution}
                        onChange={formik.handleChange}
                        className="py-4 px-5 leading-tight border focus:outline rounded-md border-gray-300 w-full"
                      />
                      {formik.touched.institution && formik.errors.institution ? (
                        <div className="text-red-500 text-sm">{formik.errors.institution}</div>
                      ) : null}
                    </div>
                  </div>
                  <div className="w-full lg:w-1/2 flex flex-col gap-y-2">
                    <div className="text-base font-nunito font-bold leading-normal text-oppty-grey-16">
                      {lang.profileQualification}
                    </div>
                    <div>
                      <input
                        type="text"
                        id="qualification"
                        name="qualification"
                        value={formik.values.qualification}
                        onChange={formik.handleChange}
                        className="py-4 px-5 leading-tight border focus:outline rounded-md border-gray-300 w-full"
                      />
                      {formik.touched.qualification && formik.errors.qualification ? (
                        <div className="text-red-500 text-sm">{formik.errors.qualification}</div>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="flex flex-col lg:flex-row gap-x-10 gap-y-5">
                  <div className="w-full lg:w-1/2 flex flex-col gap-y-2">
                    <div className="text-base font-nunito font-bold leading-normal text-oppty-grey-16">
                      {lang.profileCourse}
                    </div>
                    <div>
                      <input
                        type="text"
                        id="course"
                        name="course"
                        value={formik.values.course}
                        onChange={formik.handleChange}
                        className="py-4 px-5 leading-tight border focus:outline rounded-md border-gray-300 w-full"
                      />
                      {formik.touched.course && formik.errors.course ? (
                        <div className="text-red-500 text-sm">{formik.errors.course}</div>
                      ) : null}
                    </div>
                  </div>
                  <div className="w-full lg:w-1/2 flex flex-col gap-y-2">
                    <div className="text-base font-nunito font-bold leading-normal text-oppty-grey-16">
                      {lang.profileCountry}
                    </div>
                    <div>
                      <AsyncSelect
                        cacheOptions
                        defaultOptions
                        value={countryOptions.find((option) => option.value === formik.values.country)}
                        onChange={(selectedOption) => {
                          const selectedValue = selectedOption || '';
                          if (selectedValue) formik.setFieldValue('country', selectedValue.value);
                        }}
                        loadOptions={countryLoadOptions}
                        placeholder={lang.generalSearch}
                        isDisabled={false}
                        isClearable={true}
                        isSearchable={true}
                        name="countries"
                        id="countries"
                        menuPlacement="bottom"
                        styles={{
                          control: (provided) => ({
                            ...provided,
                            height: 54
                          }),
                          input: (provided) => ({
                            ...provided,
                            boxShadow: 'none',
                            '& input': {
                              boxShadow: 'none !important',
                              outline: 'none !important'
                            }
                          })
                        }}
                        className="w-full"
                      />
                      {formik.touched.country && formik.errors.country ? (
                        <div className="text-red-500 text-sm">{formik.errors.country}</div>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="w-full flex flex-col lg:flex-row gap-y-8 gap-x-10">
                  <div className="w-full lg:w-1/2 flex flex-col gap-y-2">
                    <div className="text-base font-nunito font-bold leading-normal text-oppty-grey-16">
                      {lang.profileStartDate}
                    </div>
                    <div className="flex flex-row gap-x-2">
                      <div className="flex flex-col w-1/2">
                        <Select
                          className="basic-single"
                          classNamePrefix="startMonth"
                          defaultValue={'Month'}
                          value={monthOptions.find((option) => option.value === formik.values.startMonth)}
                          onChange={(selectedOption) => formik.setFieldValue('startMonth', selectedOption?.value || '')}
                          placeholder={lang.generalMonth}
                          isDisabled={false}
                          isLoading={false}
                          isClearable={true}
                          isRtl={false}
                          isSearchable={true}
                          name="startMonth"
                          options={monthOptions}
                          styles={{
                            control: (provided) => ({
                              ...provided,
                              height: 54
                            }),
                            input: (provided) => ({
                              ...provided,
                              boxShadow: 'none',
                              '& input': {
                                boxShadow: 'none !important',
                                outline: 'none !important'
                              }
                            })
                          }}
                        />
                        {formik.touched.startMonth && formik.errors.startMonth ? (
                          <div className="text-red-500 text-sm">{formik.errors.startMonth}</div>
                        ) : null}
                      </div>
                      <div className="flex flex-col w-1/2">
                        <Select
                          className="basic-single"
                          classNamePrefix="startYear"
                          defaultValue={yearOptions.find((option) => option.value === formik.values.startYear)}
                          value={yearOptions.find((option) => option.value === formik.values.startYear)}
                          onChange={(selectedOption) => formik.setFieldValue('startYear', selectedOption?.value || '')}
                          placeholder={lang.generalYear}
                          isDisabled={false}
                          isLoading={false}
                          isClearable={true}
                          isRtl={false}
                          isSearchable={true}
                          name="startYear"
                          options={yearOptions}
                          styles={{
                            control: (provided) => ({
                              ...provided,
                              height: 54
                            }),
                            input: (provided) => ({
                              ...provided,
                              boxShadow: 'none',
                              '& input': {
                                boxShadow: 'none !important',
                                outline: 'none !important'
                              }
                            })
                          }}
                        />
                        {formik.touched.startYear && formik.errors.startYear ? (
                          <div className="text-red-500 text-sm">{formik.errors.startYear}</div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  {!current && (
                    <div className="w-full lg:w-1/2 flex flex-col gap-y-2">
                      <div className="text-base font-nunito font-bold leading-normal text-oppty-grey-16">
                        {lang.profileEndDate}
                      </div>
                      <div className="flex flex-row gap-x-2">
                        <div className="flex flex-col w-1/2">
                          <Select
                            className="basic-single"
                            classNamePrefix="select"
                            defaultValue={'Month'}
                            value={monthOptions.find((option) => option.value === formik.values.endMonth)}
                            onChange={(selectedOption) => formik.setFieldValue('endMonth', selectedOption?.value || '')}
                            placeholder={lang.generalMonth}
                            isDisabled={false}
                            isLoading={false}
                            isClearable={true}
                            isRtl={false}
                            isSearchable={true}
                            name="skill"
                            options={monthOptions}
                            styles={{
                              control: (provided) => ({
                                ...provided,
                                height: 54
                              }),
                              input: (provided) => ({
                                ...provided,
                                boxShadow: 'none',
                                '& input': {
                                  boxShadow: 'none !important',
                                  outline: 'none !important'
                                }
                              })
                            }}
                          />
                          {formik.touched.endMonth && formik.errors.endMonth ? (
                            <div className="text-red-500 text-sm">{formik.errors.endMonth}</div>
                          ) : null}
                        </div>
                        <div className="flex flex-col w-1/2">
                          <Select
                            className="basic-single"
                            classNamePrefix="select"
                            defaultValue={yearOptions.find((option) => option.value === formik.values.endYear)}
                            value={yearOptions.find((option) => option.value === formik.values.endYear)}
                            onChange={(selectedOption) => formik.setFieldValue('endYear', selectedOption?.value || '')}
                            placeholder={lang.generalYear}
                            isDisabled={false}
                            isLoading={false}
                            isClearable={true}
                            isRtl={false}
                            isSearchable={true}
                            name="skill"
                            options={yearOptions}
                            styles={{
                              control: (provided) => ({
                                ...provided,
                                height: 54
                              }),
                              input: (provided) => ({
                                ...provided,
                                boxShadow: 'none',
                                '& input': {
                                  boxShadow: 'none !important',
                                  outline: 'none !important'
                                }
                              })
                            }}
                          />
                          {formik.touched.endYear && formik.errors.endYear ? (
                            <div className="text-red-500 text-sm">{formik.errors.endYear}</div>
                          ) : null}
                        </div>
                      </div>
                      <div className="w-full">
                        {formik.errors.dates &&
                        formik.touched.startMonth &&
                        formik.touched.startYear &&
                        formik.touched.endMonth &&
                        formik.touched.endYear ? (
                          <div className="text-red-500 text-sm">{formik.errors.dates}</div>
                        ) : null}
                      </div>
                    </div>
                  )}
                </div>
                <div className="w-full flex flex-col gap-y-2">
                  <div className="text-base font-nunito font-bold leading-normal text-oppty-grey-16">
                    {lang.profileCertificateHighlight}
                  </div>
                  <ReactQuill
                    placeholder={lang.profileEducationDescription}
                    theme="snow"
                    value={formik.values.description}
                    onChange={(e) => formik.setFieldValue('description', e)}
                    className="bg-white"
                  />
                  {formik.touched.description && formik.errors.description ? (
                    <div className="text-red-500 text-sm">{formik.errors.description}</div>
                  ) : null}
                </div>
                <div className="flex flex-row mt-[-20px] items-center">
                  <Checkbox onChange={handleCurrentChange} value={current} />
                  <div className="text-base font-nunito font-bold leading-normal text-oppty-grey-16">
                    {lang.profileCurrent}
                  </div>
                </div>
                <div className="flex justify-end flex-row gap-x-2 mt-[-20px] pb-4">
                  <button
                    onClick={() => {
                      setAddCertificate(false);
                      setCurrent(false);
                    }}
                    className="text-base font-nunito font-semibold leading-normal bg-oppty-grey-14 text-oppty-grey-13 px-8 py-3 rounded-md"
                  >
                    {lang.profileCancel}
                  </button>
                  <button
                    type="submit"
                    className="text-base font-nunito font-semibold leading-normal bg-oppty-blue text-white px-8 py-3 rounded-md"
                  >
                    {lang.profileAdd}
                  </button>
                </div>
              </div>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default Certifications;
