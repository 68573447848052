import ReactPlayer from 'react-player';

const Video = ({ user }) => {
  return (
    <div className="w-full flex flex-col p-10 lg:p-16 shadow-md bg-[#FFF6ED]">
      <div className="text-[#604E56] pb-10">
        <div className="text-5xl font-extrabold">Video</div>
        <div className="text-xl">Check out my video</div>
      </div>
      <div className="flex flex-col pt-5 w-full">
        <div className="bg-[#FFF6ED] w-full lg:w-[550px] border-orange-400 border-2">
          <ReactPlayer url={user?.video?.videoUrl} controls={true} width="100%" />
        </div>
      </div>
    </div>
  );
};

export default Video;
