const PortfolioCard = ({ portfolio }) => {
  return (
    <div
      className="w-full lg:w-[320px] cursor-pointer transform transition-transform duration-300 hover:scale-110"
      onClick={() => window.open(portfolio?.link)}
    >
      <img src={portfolio.image} alt="pic" className="w-96 lg:w-[400px] h-56 object-cover border-[15px] border-template6-blue-2" />
      <div className="text-black font-bold pt-5 text-xl">{portfolio?.highlight}</div>
    </div>
  );
};

export default PortfolioCard;
