import { FETCH_ALL_PSYCHOMETRIC_QUESTIONS } from "../actions/psychrometricAction";

const initialState = {
  allQuestions: []
};

const PsychometricReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ALL_PSYCHOMETRIC_QUESTIONS:
      return {
        ...state,
        allQuestions: action.payload?.psychometrics?.[0]?.questions
      };
    default:
      return state;
  }
};

export default PsychometricReducer;

