const WorkExperienceCard = ({ work }) => {
  return (
    <div className="border-b-[1px] pb-5 -mx-10 px-10 border-template3-blue-1">
      <div className="text-template3-blue-2 font-semibold text-2xl">{work?.position}</div>
      <div className="flex text-black font-medium text-base items-center gap-x-2">
        <div>{work?.companyName}</div>
        <div className="h-2.5 border-r-[1px] border-black" />
        <div>
          {work?.startMonth} {work?.startYear} -{' '}
          {work?.endYear === 99 || work?.endYear === '99' ? 'Current' : `${work?.endMonth} ${work?.endYear}`}
        </div>
      </div>
      <div className="w-full pt-3" dangerouslySetInnerHTML={{ __html: work?.description }}></div>
    </div>
  );
};

export default WorkExperienceCard;

//<div className="border-b-[1px] py-7 border-gray-300 flex flex-col lg:flex-row gap-5 bg-[ #425466]">
//   <div className="w-full lg:w-1/2">
//     <div className="text-black font-bold text-3xl">{work?.position}</div>
//     <div className="bg-transparent border-[1px] border-template5-blue-1 w-fit px-4 py-1.5 text-sm font-semibold text-template5-blue-1 rounded-2xl mt-2">
//       {work?.startMonth} {work?.startYear} -{' '}
//       {work?.endYear === 99 ? 'Current' : `${work?.startMonth} ${work?.endYear}`}
//     </div>
//   </div>
//   <div className="w-full lg:w-1/2">
//     <div className="text-black text-2xl font-bold">{work?.companyName}</div>
//     {/* <div className="font-medium text-sm text-black/60">{work?.description}</div> */}
//     <div className="w-full pt-2" dangerouslySetInnerHTML={{ __html: work?.description }}></div>
//   </div>
// </div>
