import PortfolioCard from './portfolioCard';

const Portfolio = ({ user }) => {
  return (
    <div className="w-full flex flex-col p-10 lg:p-16 bg-white shadow-md border-l-[16px] border-template6-green-1">
      <div className="text-5xl font-bold mb-16 text-black text-center">Portfolio</div>
      <div className="flex flex-wrap w-full gap-5 justify-center">
        {user?.portfolios?.map((portfolio, index) => {
          return <PortfolioCard key={index} portfolio={portfolio} />;
        })}
      </div>
    </div>
  );
};

export default Portfolio;

//old code
//   <div className="w-full flex flex-col p-10 lg:p-20 shadow-md bg-template6-blue-1 border-l-[16px] border-template6-green-1">
//   <div className="text-5xl font-bold mb-20 text-white text-center">My Portfolio</div>
//   <div className="flex flex-wrap w-full gap-5 justify-center">
//     {user?.portfolios?.map((portfolio, index) => {
//       return <PortfolioCard key={index} portfolio={portfolio} />;
//     })}
//   </div>
// </div>;
