import Template1 from '../templates/template1/template1';
import Template2 from '../templates/template2/template2';
import { useDispatch, useSelector } from 'react-redux';
import Template3 from '../templates/template3/template3';
import { useEffect, useState } from 'react';
import { fetchPublicProfileSuccess, getPublicProfile } from '../../actions/publicProfileAction';
import { useParams } from 'react-router-dom';
import SkeletonLoader from '../../common/skeletonLoader';
import Template4 from '../templates/template4/template4';
import Template5 from '../templates/template5/template5';
import Template6 from '../templates/template6/template6';
import Template7 from '../templates/template7/template7';
import Template8 from '../templates/template8/template8';
import Template9 from '../templates/template9/template9';

const CvWebsiteBuild = () => {
  const dispatch = useDispatch();
  const { publicId } = useParams();

  console.log('publicId: ', publicId);

  const [loader, setLoader] = useState(true);

  const user = useSelector((state) => state.publicProfile?.user);
  console.log('user: ', user);

  useEffect(() => {
    dispatch(fetchPublicProfileSuccess({}))
    dispatch(getPublicProfile(publicId, setLoader));
  }, [publicId, dispatch]);

  return loader || !user ? (
    <SkeletonLoader />
  ) : (
    <div className='flex flex-col justify-center items-center w-full p-1'>
      {user?.template ? (
        <div className='min-w-full lg:min-w-[1200px] max-w-[1200px] rounded-xl'>
          {user?.template === 'template1' && <Template1 user={user} />}
          {user?.template === 'template2' && <Template2 user={user} />}
          {user?.template === 'template3' && <Template3 user={user} />}
          {user?.template === 'template4' && <Template4 user={user} />}
          {user?.template === 'template5' && <Template5 user={user} />}
          {user?.template === 'template6' && <Template6 user={user} />}
          {user?.template === 'template7' && <Template7 user={user} />}
          {user?.template === 'template8' && <Template8 user={user} />}
          {user?.template === 'template9' && <Template9 user={user} />}
        </div>
      ) : (
        <div className="flex w-full h-screen justify-center items-center">
          <div className="text-3xl font-semibold">Please select the template</div>
        </div>
      )}
    </div>
  );
};

export default CvWebsiteBuild;
