import CountryCodeDropdown from '../../components/countryCodeDropdown';
import { useState } from 'react';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import ReactQuill from 'react-quill';
import { addEmployerGeneralDetails } from '../../actions/employerAction';
import {
  DevicePhoneMobileIcon,
  EnvelopeIcon,
  DocumentTextIcon,
  HomeIcon,
  BuildingOffice2Icon,
  WrenchScrewdriverIcon
} from '@heroicons/react/24/outline';
import profileEdit from '../../assets/icons/pencil.svg';
import DefaultProfile from '../../assets/images/Default_profilepic.png';
import Select from 'react-select';
import { companySizeOptions } from '../../constant/dropdownConstOptions';
import industryOptions from '../../constant/all_industries.json';
import AsyncSelect from 'react-select/async';
import countryOptions from '../../constant/countries.json';
import { employerGeneralSchema } from '../../validations/validationSchemas';

const stripHtmlTags = (html) => {
  const doc = new DOMParser().parseFromString(html, 'text/html');
  return doc.body.textContent || '';
};

const EmployerGeneralDetails = () => {
  const dispatch = useDispatch();

  const [editGeneral, setEditGeneral] = useState(false);

  const lang = useSelector((state) => state.language.lang);
  const employer = useSelector((state) => state.employer);

  console.log(employer);

  const handleCompanyDescriptionChange = (value) => {
    formik.setFieldValue('companyDescription', value);
  };

  const handleAreaCodeSelect = (areaCode) => {
    console.log('areaCode: ', areaCode);
    formik.setValues({
      ...formik.values,
      areaCode: areaCode
    });
  };

  const handleEditClick = () => {
    setEditGeneral(true);
  };

  const handleCancel = () => {
    setEditGeneral(false);
    formik.setValues(initialValues);
  };

  const initialValues = {
    companyName: employer?.companyName || '',
    email: employer?.email || '',
    areaCode: employer?.areaCode || '65',
    mobile: employer?.mobile || '',
    // currentlyHiring: employer?.currentlyHiring || false,
    address: employer?.address || '',
    state: employer?.state || '',
    city: employer?.city || '',
    companyLocation: employer?.companyLocation || '',
    companySize: employer?.companySize || '',
    companyIndustry: employer?.companyIndustry || '',
    postal: employer?.postal || '',
    companyDescription: employer?.companyDescription || ''
  };

  const formik = useFormik({
    initialValues,
    validationSchema: employerGeneralSchema,
    onSubmit: async (values) => {
      console.log('value: ', values);
      const result = await dispatch(addEmployerGeneralDetails(values));
      if (result && !result.error) {
        setEditGeneral(false);
      }
    }
  });

  const countryLoadOptions = (inputValue, callback) => {
    const filteredOptions = countryOptions
      .filter((option) => option.label.toLowerCase().includes(inputValue.toLowerCase()))
      .slice(0, 20);

    console.log('filteredOptions: ', filteredOptions);
    callback(filteredOptions);
  };

  const plainTextDescription = stripHtmlTags(formik.values.companyDescription);
  const descriptionLength = plainTextDescription.length;
  return (
    <div className="flex flex-col justify-center pt-2 pb-5 px-0 lg:px-8 rounded-md bg-white ">
      <form onSubmit={formik.handleSubmit}>
        <div className="flex justify-between items-center">
          <div>
            <div className="text-lg lg:text-2xl font-raleway font-bold uppercase leading-normal text-black pb-[1px]">
              {lang.profileEditProfile}
            </div>
          </div>
          {/* <div className="flex flex-row gap-x-8 items-center">
            <div className="text-base font-nunito font-semibold leading-normal text-oppty-grey-12">
              {lang.companyCurrentlyHiring}
            </div>
            <div className="inline-flex items-center">
              <div className="relative inline-block w-8 h-4 rounded-full cursor-pointer">
                <input
                  id="currentlyHiring"
                  name="currentlyHiring"
                  type="checkbox"
                  checked={formik.values.currentlyHiring}
                  onChange={formik.handleChange}
                  className="absolute w-8 h-4 transition-colors duration-300 rounded-full appearance-none cursor-pointer peer bg-blue-gray-100 checked:bg-oppty-purple-1 peer-checked:border-oppty-purple-1 peer-checked:before:bg-oppty-purple-1"
                />
                <label
                  htmlFor="currentlyHiring"
                  className="before:content[''] absolute top-2/4 -left-1 h-6 w-6 -translate-y-2/4 cursor-pointer rounded-full border border-blue-gray-100 bg-white checked:bg-oppty-blue peer:bg-oppty-blue peer-checked:bg-oppty-blue shadow-md transition-all duration-300 before:absolute before:top-2/4 before:left-2/4 before:block before:h-10 before:w-10 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-oppty-blue before:opacity-0 before:transition-opacity hover:before:opacity-10 peer-checked:translate-x-full peer-checked:border-oppty-blue peer-checked:before:bg-oppty-blue"
                >
                  <div
                    className="inline-block p-5 rounded-full top-2/4 left-2/4 -translate-x-2/4 -translate-y-2/4"
                    data-ripple-dark="true"
                  ></div>
                </label>
              </div>
            </div>
          </div> */}
        </div>
        <div className="flex flex-row items-center pt-5">
          <div className="text-xl font-nunito font-bold leading-normal uppercase  text-black pr-5">
            {lang.employeeHomeTabGeneral}
          </div>
          <div className="flex-grow border-t-[1px] border-oppty-grey-15"></div>
        </div>
        <div className="flex flex-col justify-center pb-5">
          {editGeneral ? (
            <div>
              <div className="flex flex-col py-7 gap-y-10">
                <div className="w-full flex flex-col gap-y-2">
                  <div className="text-base font-nunito font-bold leading-normal text-oppty-grey-16">
                    {lang.createCompanyName}
                  </div>
                  <input
                    type="text"
                    id="companyName"
                    name="companyName"
                    onChange={formik.handleChange}
                    value={formik.values.companyName}
                    className="py-4 px-5 leading-tight border focus:outline rounded-md border-gray-300"
                  />
                  {formik.touched.companyName && formik.errors.companyName ? (
                    <div className="text-red-500 text-sm">{formik.errors.companyName}</div>
                  ) : null}
                </div>
                <div className="flex flex-col lg:flex-row gap-x-10 gap-y-5">
                  <div className="w-full lg:w-1/2 flex flex-col gap-y-2">
                    <div className="text-base font-nunito font-bold leading-normal text-oppty-grey-16">
                      {lang.generalContactNumber}
                    </div>
                    <div className="flex w-full">
                      <CountryCodeDropdown onSelect={handleAreaCodeSelect} value={formik.values.areaCode} />
                      <input
                        type="text"
                        id="mobile"
                        name="mobile"
                        onChange={formik.handleChange}
                        value={formik.values.mobile}
                        className="w-full py-4 px-5 leading-tight border focus:outline rounded-r-md border-gray-300"
                      />
                    </div>
                    {formik.touched.areaCode && formik.errors.areaCode ? (
                      <div className="text-red-500 text-sm">{formik.errors.areaCode}</div>
                    ) : null}{' '}
                    {formik.touched.mobile && formik.errors.mobile ? (
                      <div className="text-red-500 text-sm">{formik.errors.mobile}</div>
                    ) : null}
                  </div>

                  <div className="w-full lg:w-1/2 flex flex-col gap-y-2">
                    <div className="text-base font-nunito font-bold leading-normal text-oppty-grey-16">
                      {lang.createEmail}
                    </div>
                    <input
                      type="text"
                      id="email"
                      name="email"
                      onChange={formik.handleChange}
                      value={formik.values.email}
                      disabled
                      className="py-4 px-5 leading-tight border focus:outline rounded-md border-gray-300 disabled:bg-gray-200"
                    />
                    {formik.touched.email && formik.errors.email ? (
                      <div className="text-red-500 text-sm">{formik.errors.email}</div>
                    ) : null}
                  </div>
                </div>
                <div className="w-full flex flex-col gap-y-2">
                  <div className="text-base font-nunito font-bold leading-normal text-oppty-grey-16">
                    {lang.profileAddress}
                  </div>
                  <input
                    type="text"
                    id="address"
                    name="address"
                    onChange={formik.handleChange}
                    value={formik.values.address}
                    className="py-4 px-5 leading-tight border focus:outline rounded-md border-gray-300"
                  />
                  {formik.touched.address && formik.errors.address ? (
                    <div className="text-red-500 text-sm">{formik.errors.address}</div>
                  ) : null}
                </div>
                <div className="flex flex-col lg:flex-row gap-x-10 gap-y-5">
                  <div className="w-full lg:w-1/2 flex flex-col gap-y-2">
                    <div className="text-base font-nunito font-bold leading-normal text-oppty-grey-16">
                      {lang.profileCountry}
                    </div>
                    <AsyncSelect
                      cacheOptions
                      defaultOptions
                      value={countryOptions.find((option) => option.value === formik.values.companyLocation)}
                      onChange={(selectedOption) => {
                        const selectedValue = selectedOption || '';
                        if (selectedValue) formik.setFieldValue('companyLocation', selectedValue.value);
                      }}
                      loadOptions={countryLoadOptions}
                      isDisabled={false}
                      isClearable={true}
                      isSearchable={true}
                      name="countries"
                      id="countries"
                      menuPlacement="bottom"
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          height: 54
                        }),
                        input: (provided) => ({
                          ...provided,
                          boxShadow: 'none',
                          '& input': {
                            boxShadow: 'none !important',
                            outline: 'none !important'
                          }
                        })
                      }}
                      placeholder="Select Country"
                      className="w-full"
                    />
                    {formik.touched.companyLocation && formik.errors.companyLocation ? (
                      <div className="text-red-500 text-sm">{formik.errors.companyLocation}</div>
                    ) : null}
                  </div>
                  <div className="w-full lg:w-1/2 flex flex-col gap-y-2">
                    <div className="text-base font-nunito font-bold leading-normal text-oppty-grey-16">
                      {lang.profilePostalCode}
                    </div>
                    <input
                      type="text"
                      id="postal"
                      name="postal"
                      onChange={formik.handleChange}
                      value={formik.values.postal}
                      className="py-4 px-5 leading-tight border focus:outline rounded-md border-gray-300"
                    />
                    {formik.touched.postal && formik.errors.postal ? (
                      <div className="text-red-500 text-sm">{formik.errors.postal}</div>
                    ) : null}
                  </div>
                </div>
                <div className="flex flex-col lg:flex-row gap-x-10 gap-y-5">
                  <div className="w-full lg:w-1/2 flex flex-col gap-y-2">
                    <div className="text-base font-nunito font-bold leading-normal text-oppty-grey-16">
                      {lang.companyIndustry}
                    </div>
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      defaultValue={'Select Industry'}
                      value={industryOptions.find((option) => option.value === formik.values.companyIndustry)}
                      onChange={(selectedOption) =>
                        formik.setFieldValue('companyIndustry', selectedOption?.value || '')
                      }
                      isDisabled={false}
                      isLoading={false}
                      isClearable={false}
                      isRtl={false}
                      isSearchable={true}
                      name="proficiency"
                      placeholder=""
                      options={industryOptions}
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          height: 52,
                          paddingLeft: 10
                        }),
                        input: (provided) => ({
                          ...provided,
                          boxShadow: 'none',
                          '& input': {
                            boxShadow: 'none !important',
                            outline: 'none !important'
                          }
                        })
                      }}
                    />
                    {formik.touched.companyIndustry && formik.errors.companyIndustry ? (
                      <div className="text-red-500 text-sm">{formik.errors.companyIndustry}</div>
                    ) : null}
                  </div>
                  <div className="w-full lg:w-1/2 flex flex-col gap-y-2">
                    <div className="text-base font-nunito font-bold leading-normal text-oppty-grey-16">
                      {lang.companySize}
                    </div>
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      defaultValue={'Select Size'}
                      value={companySizeOptions.find((option) => option.value === formik.values.companySize)}
                      onChange={(selectedOption) => formik.setFieldValue('companySize', selectedOption?.value || '')}
                      isDisabled={false}
                      isLoading={false}
                      isClearable={false}
                      isRtl={false}
                      isSearchable={false}
                      name="proficiency"
                      options={companySizeOptions}
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          height: 52,
                          paddingLeft: 10
                        })
                      }}
                    />
                    {formik.touched.companySize && formik.errors.companySize ? (
                      <div className="text-red-500 text-sm">{formik.errors.companySize}</div>
                    ) : null}
                  </div>
                </div>
                <div className="w-full flex flex-col gap-y-2">
                  <div className="text-base font-nunito font-bold leading-normal text-oppty-grey-16">
                    {lang.companyDescription}
                  </div>
                  <ReactQuill
                    theme="snow"
                    value={formik.values.companyDescription}
                    onChange={handleCompanyDescriptionChange}
                  />
                  <p className={`text-xs ${descriptionLength > 1000 ? 'text-red-500' : ''}`}>
                    {descriptionLength}/{1000}
                  </p>
                  {formik.touched.companyDescription && formik.errors.companyDescription ? (
                    <div className="text-red-500 text-sm">{formik.errors.companyDescription}</div>
                  ) : null}
                </div>
              </div>
              <div className="flex w-full gap-x-4 pt-5 justify-end">
                <button
                  onClick={handleCancel}
                  className="text-base font-nunito font-semibold leading-normal bg-oppty-grey-14 text-oppty-grey-13 px-8 py-3 rounded-md"
                >
                  {lang.profileCancel}
                </button>
                <button
                  type="submit"
                  className="text-base font-nunito font-semibold leading-normal bg-oppty-blue text-white px-8 py-3 rounded-md"
                >
                  {lang.profileSave}
                </button>
              </div>
            </div>
          ) : (
            <div className="relative border rounded-md border-gray-300 bg-oppty-grey-17 p-5 mt-5 flex justify-between">
              <div>
                <div className="text-xl lg:text-3xl font-semibold mb-7">{employer.companyName}</div>
                {employer?.mobile && (
                  <div className="flex gap-x-2 items-center mb-4">
                    <DevicePhoneMobileIcon className="h-6 w-6" />
                    <div className="text-lg font-medium">
                      +{employer.areaCode} {employer.mobile}
                    </div>
                  </div>
                )}
                {employer?.email && (
                  <div className="flex gap-x-2 items-center mb-4">
                    <EnvelopeIcon className="h-6 w-6" />
                    <div className="text-lg font-medium">{employer.email}</div>
                  </div>
                )}
                {employer?.address && (
                  <div className="flex gap-x-2 items-start mb-4">
                    <HomeIcon className="h-6 w-6" />
                    <div>
                      <div className="text-lg font-medium">
                        {employer.address} {employer.companyLocation} {employer.postal}
                      </div>
                    </div>
                  </div>
                )}
                {employer?.companySize && (
                  <div className="flex gap-x-2 items-center mb-4">
                    <BuildingOffice2Icon className="h-6 w-6" />
                    <div>
                      <div className="text-lg font-medium">{employer.companySize}</div>
                    </div>
                  </div>
                )}
                {employer?.companyIndustry && (
                  <div className="flex gap-x-2 items-center mb-8">
                    <WrenchScrewdriverIcon className="h-6 w-6" />
                    <div>
                      <div className="text-lg font-medium">{employer.companyIndustry}</div>
                    </div>
                  </div>
                )}
                {employer?.companyDescription && (
                  <div className="flex items-start">
                    <div className="flex-shrink-0 h-6 w-6">
                      <DocumentTextIcon className="h-full w-full" />
                    </div>
                    <div
                      className="ml-2 text-base"
                      dangerouslySetInnerHTML={{ __html: employer.companyDescription }}
                    ></div>
                  </div>
                )}
              </div>
              <button
                onClick={handleEditClick}
                className="absolute right-5 first-letter:flex justify-center w-8 h-8 p-2 rounded-md hover:bg-green-400"
              >
                <img src={profileEdit} alt="profile icon" className="h-full w-full" />
              </button>
            </div>
          )}
        </div>
      </form>
    </div>
  );
};

export default EmployerGeneralDetails;
