const QuestionAndAnswerCard = ({ question, index, selectedAnswers, handleAnswerSelect }) => {
  const isSelected = (answerId) => {
    return selectedAnswers.some((item) => item.answerId === answerId);
  };

  return (
    <div className="p-1 lg:p-5">
      <div className="text-xl font-semibold">
        {index + 1}. {question?.question}
      </div>
      <div className="flex flex-col gap-2 ml-5 mt-2">
        {question?.answers?.map((ans) => {
          const selected = isSelected(ans?.answerId);
          return (
            <button
              key={ans?._id}
              className={`text-gray-600 flex border-2 w-fit p-2 rounded-md text-left ${
                selected ? "border-green-500 bg-green-200" : "border-oppty-blue hover:border-green-500"
              }`}
              onClick={() => handleAnswerSelect(question.questionId, ans.answerId, ans.traitId)}
            >
              {ans?.answer}
            </button>
          );
        })}
      </div>
    </div>
  );
};

export default QuestionAndAnswerCard;
