const EducationCard = ({ education }) => {
  return (
    <div className="flex flex-col bg-template9-gray-1 p-8 border-t-4 border-template9-orange-1 w-full lg:w-[47%] h-fit">
      <div className="text-white text-2xl font-semibold">
        {education?.qualification} in {education?.course}
      </div>
      <div className="text-base text-template9-orange-1 w-fit mt-1 underline">
        {education?.startMonth} {education?.startYear} -{' '}
        {(education?.endYear === 99 || education?.endYear === '99') ? 'Current' : `${education?.endMonth} ${education?.endYear}`}
        </div>
      <div className="text-white text-lg font-bold">{education?.institution}</div>
      {/* <div className="font-normal text-xs text-gray-200 mt-2">{education?.description}</div> */}
      <div className="w-full mt-3 text-white" dangerouslySetInnerHTML={{ __html: education?.description }}></div>
    </div>
  );
};

export default EducationCard;
