import { combineReducers } from 'redux';
import userReducer, { selectedUserReducer, userLoginReducer } from './userReducer';
import languageReducer from './languageReducer';
import userPreRegisterReducer from './userPreRegisterReducer';
import employerReducer, { employerLoginReducer, selectedEmployerReducer } from './employerReducer';
import employerPreRegisterReducer from './employerPreRegisterReducer';
import jobReducer, { selectedJobReducer } from './jobReducer';
import processingReducer from './processingReducer';
import generalReducer from './generalReducer';
import messagesReducer from './messageReducer';
import currentAuthReducer from './currentAuthReducer';
import notificationsReducer from './notificationsReducer';
import publicProfileReducer from './publicProfileReducer';
import adminReducer, { adminLoginReducer } from './adminReducer';
import publicReducer from './publicReducer';
import Psychometric from '../screens/psychometric/psychometric';
import PsychometricReducer from './psychometricReducer';

const rootReducer = combineReducers({
  user: userReducer,
  job: jobReducer,
  language: languageReducer,
  userPreRegister: userPreRegisterReducer,
  employer: employerReducer,
  employerPreRegister: employerPreRegisterReducer,
  userLogin: userLoginReducer,
  selectedEmployer: selectedEmployerReducer,
  selectedJob: selectedJobReducer,
  employerLogin: employerLoginReducer,
  processing: processingReducer,
  general: generalReducer,
  message: messagesReducer,
  selectedUser: selectedUserReducer,
  currentId: currentAuthReducer,
  notifications: notificationsReducer,
  publicProfile: publicProfileReducer,
  adminLogin: adminLoginReducer,
  admin: adminReducer,
  public: publicReducer,
  psychometric: PsychometricReducer
});

export default rootReducer;
