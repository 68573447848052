const EducationCard = ({ education }) => {
  return (
    <div className="border-b-[1px] py-7 border-gray-300 flex flex-col lg:flex-row gap-5">
      <div className="w-full lg:w-1/2">
        <div className="text-black font-bold text-3xl">
          {education?.qualification} in {education?.course}
        </div>
        <div className="bg-transparent border-[1px] border-template5-blue-1 w-fit px-4 py-1.5 text-sm font-semibold text-template5-blue-1 rounded-2xl mt-2">
          {education?.startMonth} {education?.startYear} -{' '}
          {education?.endYear === 99 || education?.endYear === '99'
            ? 'Current'
            : `${education?.endMonth} ${education?.endYear}`}
        </div>
      </div>
      <div className="w-full lg:w-1/2">
        <div className="text-black text-2xl font-semibold">{education?.institution}</div>
        {/* <div className="font-medium text-sm text-black/60">{education?.description}</div> */}
        <div className="w-full pt-3" dangerouslySetInnerHTML={{ __html: education?.description }}></div>
      </div>
    </div>
  );
};

export default EducationCard;
