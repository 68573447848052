import { useEffect, useState } from 'react';
import JobCard from './jobCard';
import JobDetailCard from './jobDetailCard';
import { useDispatch, useSelector } from 'react-redux';
import { addJobViewCount } from '../../../actions/jobAction';

const Jobs = () => {
  const dispatch = useDispatch();

  const [selectedJob, setSelectedJob] = useState(null);

  const lang = useSelector((state) => state.language.lang);
  const allJobs = useSelector((state) => state.job.allJobs);
  const user = useSelector((state) => state.user);

  const handleJobCardClick = (job) => {
    const isApplied = job?.applicants?.some((applicant) => applicant?.user === user?.userId);
    const newJob = {
      ...job,
      isApplied
    };
    setSelectedJob(newJob);
    dispatch(addJobViewCount({ jobId: job?._id }));
  };

  useEffect(() => {
    setSelectedJob(null)
  }, [allJobs])

  return (
    <div className="mx-auto w-full flex justify-center lg:min-h-[500px]">
      <div className="flex py-10 px-4 lg:px-10 gap-x-6 w-full lg:max-w-[1200px] bg-white rounded-xl">
        <div
          className={`${selectedJob ? 'hidden md:flex' : 'flex'} flex-col max-w-full md:max-w-[330px] lg:max-w-[440px] gap-y-5 overflow-y-auto`}
        >
          {allJobs?.map((job, index) => {
            const isApplied = job?.applicants?.some((applicant) => applicant?.user === user?.userId);
            return (
              <JobCard
                job={job}
                user={user}
                onClick={() => handleJobCardClick(job)}
                key={index}
                isApplied={isApplied}
              />
            );
          })}
        </div>
        {allJobs.length > 0 && (
          <div className={`flex-col max-w-[650px] ${selectedJob ? 'md:flex' : 'hidden md:flex'}`}>
            <div className="sticky top-0">
              <div className="job-detail-container md:max-h-screen overflow-y-auto">
                {selectedJob ? (
                  <JobDetailCard job={selectedJob} user={user} setSelectedJob={setSelectedJob} />
                ) : (
                  <div className="flex flex-col justify-center items-center pt-40 ml-28">
                    <div className="text-3xl font-bold">{lang.generalNotSelectedJobPost}</div>
                    <div className="text-xl font-normal">{lang.generalSelectJobPost}</div>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
        {allJobs.length <= 0 && (
            <div className="w-full text-left text-xl lg:text-2xl font-bold pt-5">{lang.generalNoJobs}</div>
        )}
      </div>
    </div>
  );
};

export default Jobs;
