import ItemTile from './itemTile';

const AboutMeSection = ({ user }) => {
  return (
    <div className="flex flex-col lg:flex-row w-full p-5 lg:px-16 lg:pt-32 gap-10 justify-center items-center lg:items-start h-full">
      <div className="h-96 w-full lg:max-w-[500px] lg:max-h-96 lg:w-[550px] lg:h-96 relative bg-orange-50">
        {user?.avatar ? (
          <img src={user?.avatar} alt="pic" className="w-full h-full object-cover rounded-lg" />
        ) : (
          <svg
            viewBox="0 0 444 482"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="absolute p-10 w-full h-full"
          >
            <path
              d="M222 241C255.645 241 287.911 228.331 311.702 205.78C335.492 183.228 348.857 152.642 348.857 120.75C348.857 88.8577 335.492 58.2717 311.702 35.7204C287.911 13.1692 255.645 0.5 222 0.5C188.355 0.5 156.089 13.1692 132.298 35.7204C108.508 58.2717 95.1429 88.8577 95.1429 120.75C95.1429 152.642 108.508 183.228 132.298 205.78C156.089 228.331 188.355 241 222 241ZM176.708 286.094C79.0875 286.094 0 361.062 0 453.598C0 469.005 13.1813 481.5 29.4348 481.5H414.565C430.819 481.5 444 469.005 444 453.598C444 361.062 364.913 286.094 267.292 286.094H176.708Z"
              fill="rgba(60, 84, 189)"
              fill-opacity="0.12"
            />
          </svg>
        )}
      </div>
      <div className="w-full lg:w-1/2 lg:min-h-96 p-5 lg:p-0 flex flex-col lg:justify-start items-center lg:items-start mt-5 lg:mt-0">
        <div className="w-full">
          {user?.skills?.length > 0 && (
            <div className="flex gap-x-2 text-5xl font-extrabold pb-4">
              <div className="text-[#604E56]">Skills</div>
            </div>
          )}
          <div className="flex flex-wrap gap-5 mb-14">
            {user?.skills?.map((skill, index) => (
              <ItemTile item={skill?.skill} level={skill?.level} key={index} />
            ))}
          </div>
          {user?.languages?.length > 0 &&<div className="flex gap-x-2 text-5xl font-extrabold pb-4">
              <div className="text-[#604E56]">Languages</div>
            </div>}
          <div className="flex flex-wrap gap-5">
            {user?.languages?.map((lang, index) => (
              <ItemTile item={lang?.lang} level={lang?.level} key={index} />
            ))}
          </div>
        </div>
        {/* <div className="lg:pr-32 mt-4 lg:mt-0">
          {user?.aboutMe}
        </div> */}
        {/* <div className="lg:pr-0 mt-4 lg:mt-0" dangerouslySetInnerHTML={{ __html: user?.aboutMe }}></div> */}
      </div>
    </div>
  );
};

export default AboutMeSection;
