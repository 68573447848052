import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { useFormik } from 'formik';
import { PresentationChartBarIcon } from '@heroicons/react/24/outline';
import UploadPortfolioImage from '../../../common/uploadPortfolioImage';
import PortfolioTile from '../../../common/portfolioTile';
import { portfolioAdd, portfolioDelete } from '../../../actions/userAction';
import { portfolioSchema } from '../../../validations/validationSchemas';

const Portfolios = () => {
  const dispatch = useDispatch();

  const [addPortfolio, setAddPortfolio] = useState(false);
  const [previewPortfolioImage, setPreviewPortfolioImage] = useState(null);
  const [loading, setLoading] = useState(false);

  const lang = useSelector((state) => state.language.lang);
  const user = useSelector((state) => state.user);
  const formData = new FormData();

  const formik = useFormik({
    initialValues: {
      link: '',
      highlight: '',
      fileName: ''
    },
    validationSchema: portfolioSchema,
    onSubmit: async (values, { resetForm }) => {
      setLoading(true);
      console.log('value: ', values);
      const portfolio = {
        link: formik.values.link,
        highlight: formik.values.highlight
      };
      formData.append('portfolio', JSON.stringify(portfolio));
      formData.append('file', formik.values.blob, formik.values.fileName);
      dispatch(portfolioAdd(formData, resetForm, setAddPortfolio, setLoading));
      // resetForm();
      // setAddPortfolio(false);
    }
  });

  const handleInputImage = (blob, fileName) => {
    formik.setFieldValue('blob', blob);
    formik.setFieldValue('fileName', fileName);
  };

  const handleDeleteImage = () => {
    formik.setFieldValue('file', '');
    setPreviewPortfolioImage(null);
  };

  const handleDeletePortfolio = (index) => {
    const deletePortfolio = user.portfolios[index];
    dispatch(portfolioDelete(deletePortfolio));
  };

  const handleCancelClick = () => {
    setAddPortfolio(false);
    setPreviewPortfolioImage(null);
    formik.setValues({ pic: '', link: '' });
  };

  const handleOpenLink = (link) => {
    console.log('open');

    if (!link.includes('http://') && !link.includes('https://')) {
      link = 'https://' + link;
    }

    window.open(link);
  };

  console.log('formik: ', formik.values);

  return (
    <div
      id="portfolios"
      className="flex flex-col justify-center py-5 lg:py-10 px-2 mx-2 lg:px-16 lg:mx-16 rounded-[20px] bg-white"
    >
      <div className="flex flex-row items-center gap-x-2 lg:gap-x-5">
        <div className="flex justify-center w-14 h-14 p-4 rounded-full bg-oppty-blue-5">
          <PresentationChartBarIcon className="w-full h-full stroke-oppty-blue" />
        </div>
        <div className="text-lg lg:text-2xl font-raleway font-bold leading-normal uppercase  text-black pr-5">
          {lang.profilePortfolios}
        </div>
        <div className="flex-grow border-t-[1px] border-oppty-grey-15"></div>
        {!addPortfolio && (
          <button
            onClick={() => {
              setPreviewPortfolioImage(null);
              setAddPortfolio(true);
            }}
            className="text-base font-nunito font-bold leading-normal bg-black text-white px-6 py-1 rounded-md"
          >
            + {lang.profileAdd}
          </button>
        )}
      </div>
      <div className="flex flex-col justify-center py-3 pl-0 md:pl-16">
        <div className="flex flex-wrap gap-x-5 pb-7 gap-y-2">
          {user?.portfolios?.map((portfolio, index) => {
            return (
              <PortfolioTile
                // portfolio={portfolio.portfolio}
                image={portfolio.image}
                highlight={portfolio.highlight}
                link={portfolio.link}
                key={index}
                onDelete={() => handleDeletePortfolio(index)}
                onOpenLink={() => handleOpenLink(portfolio.link)}
              />
            );
          })}
        </div>
        {addPortfolio && (
          <form onSubmit={formik.handleSubmit}>
            <div>
              <div className="flex-grow border-t-[1px] pt-3 border-oppty-grey-15"></div>
              <div className="flex flex-col lg:flex-row gap-x-10 gap-y-5">
                <div className="w-full lg:w-1/2 flex flex-col gap-y-2">
                  <UploadPortfolioImage
                    handleInputImage={handleInputImage}
                    handleDeleteImage={handleDeleteImage}
                    setPreviewPortfolioImage={setPreviewPortfolioImage}
                    pic={previewPortfolioImage}
                  />
                  {formik.touched.fileName && formik.errors.fileName ? (
                    <div className="text-red-500 text-sm">{formik.errors.fileName}</div>
                  ) : null}
                </div>
                <div className="w-full lg:w-1/2 flex flex-col gap-y-2">
                  <div className="text-base font-nunito font-bold leading-normal text-oppty-grey-16">
                    {lang.generalLink}
                  </div>
                  <input
                    type="text"
                    id="link"
                    name="link"
                    onChange={formik.handleChange}
                    value={formik.values.link}
                    className="w-full py-4 px-5 leading-tight border focus:outline rounded-md border-gray-300"
                  />
                  {formik.touched.link && formik.errors.link ? (
                    <div className="text-red-500 text-sm">{formik.errors.link}</div>
                  ) : null}
                  <div className="mt-5 text-base font-nunito font-bold leading-normal text-oppty-grey-16">
                    {lang.generalHighlights}
                  </div>
                  <textarea
                    type="text"
                    id="highlight"
                    name="highlight"
                    onChange={formik.handleChange}
                    value={formik.values.highlight}
                    className="w-full py-4 px-5 leading-tight border focus:outline rounded-md border-gray-300"
                  />
                  {formik.touched.highlight && formik.errors.highlight ? (
                    <div className="text-red-500 text-sm">{formik.errors.highlight}</div>
                  ) : null}
                </div>
              </div>
              <div className="flex justify-end flex-row gap-x-2 pt-10 pb-5">
                <button
                  type="button"
                  onClick={handleCancelClick}
                  className="text-base font-nunito font-semibold leading-normal bg-oppty-grey-14 text-oppty-grey-13 px-8 py-3 rounded-md"
                >
                  {lang.profileCancel}
                </button>
                <button
                  type="submit"
                  disabled={loading}
                  className="text-base font-nunito font-semibold leading-normal bg-oppty-blue text-white w-24 h-14 rounded-md disabled:bg-gray-500"
                >
                  {loading ? (
                    <div className="w-full flex justify-center">
                      <div className="border-gray-300 h-10 w-10 animate-spin rounded-full border-8 border-t-blue-600" />
                    </div>
                  ) : (
                    lang.profileAdd
                  )}
                </button>
              </div>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default Portfolios;
