const EducationCard = ({ education }) => {
  return (
    <div className="border-l-[1px] px-10 border-template4-green-2">
      <div className="text-black font-semibold text-xl">
        {education?.qualification} in {education?.course}
      </div>
      <div className="text-gray-500 text-base">{education?.institution}</div>
      <div className="bg-gray-100 w-fit px-4 py-2 text-sm text-gray-500 rounded-2xl my-1 font-semibold">
        {education?.startMonth} {education?.startYear} -{' '}
        {education?.endYear === 99 || education?.endYear === '99'
          ? 'Current'
          : `${education?.endMonth} ${education?.endYear}`}
      </div>
      {/* <div className="font-medium text-sm pt-3">{education?.description}</div> */}
      <div className="w-full pt-3" dangerouslySetInnerHTML={{ __html: education?.description }}></div>
    </div>
  );
};

export default EducationCard;
