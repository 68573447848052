import ReactPlayer from 'react-player';

const Video = ({ user }) => {
  console.log('url: ', user?.video?.videoUrl);
  return (
    <div className="w-full flex flex-col p-5 lg:p-10 border-2 border-template3-blue-1 rounded-xl shadow-md">
      <div className="text-4xl font-bold">Video</div>
      <div className="text-lg">Check out my video</div>
      <div className="flex flex-col pt-5 w-full">
        <div className="bg-purple-500/5 w-full lg:w-[550px] border-purple-500 border-2">
        <ReactPlayer url={user?.video?.videoUrl} controls={true} width="100%" />
        </div>
      </div>
    </div>
  );
};

export default Video;
