const WorkExperienceCard = ({ work }) => {
  return (
    <div className="flex flex-col lg:flex-row border-l-2 border-gray-600 pl-5">
      <div className="w-full lg:w-1/2">
        <div className="text-white text-2xl font-semibold border-l-2 border-blue-500 -ml-[22px] pl-[17px]">
          {work?.position}
        </div>
        <div className="text-gray-100 text-base">{work?.companyName}</div>
        <div className="text-sm font-semibold text-black rounded-2xl bg-template8-purple-1 w-fit px-4 py-2 mt-2">
          {work?.startMonth} {work?.startYear} -{' '}
          {work?.endYear === 99 || work?.endYear === '99' ? 'Current' : `${work?.endMonth} ${work?.endYear}`}
        </div>
      </div>
      {/* <div className="font-medium text-sm text-white mt-2 lg:mt-0 w-full lg:w-1/2">{work?.description}</div> */}
      <div
        className="text-white mt-2 lg:mt-0 w-full lg:w-1/2"
        dangerouslySetInnerHTML={{ __html: work?.description }}
      ></div>
    </div>
  );
};

export default WorkExperienceCard;
