import React, { useState, useEffect, useRef } from 'react';
import logo from '../assets/images/headerLogo.svg';
import { languageLoader } from '../actions/languageAction';
import { useDispatch, useSelector } from 'react-redux';
import message from '../assets/images/Message.svg';
import notification from '../assets/images/notification-bing.svg';
import defaultAvatar from '../assets/images/Default_profilepic.png';
import HeaderProfileDropdownMenu from '../components/headerProfileDropdown';
import Notifications from '../notifications/notifications';
import { SocketContextProvider } from '../context/socketContext';
import useListenNotifications from '../reducers/listenNotifications';
import { useNavigate } from 'react-router-dom';
import { BuildingStorefrontIcon } from '@heroicons/react/24/outline';

const Header = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const pathname = window.location.pathname;

  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const lang = useSelector((state) => state.language.lang);
  const isUserLogin = useSelector((state) => state.userLogin);
  const isEmployerLogin = useSelector((state) => state.employerLogin);
  const notifications = useSelector((state) => state.notifications.notifications);
  const employer = useSelector((state) => state.employer);

  console.log(notifications);

  const unreadCount = notifications?.filter((notification) => !notification.isRead).length;

  useListenNotifications();

  console.log('isUserLogin: ', isUserLogin);
  console.log('isEmployerLogin: ', isEmployerLogin);

  const localLang = localStorage.getItem('lan');

  // Handle language change
  const handleLanguageChange = (e) => {
    dispatch(languageLoader(e.target.value));
    localStorage.setItem('lan', e.target.value);
  };

  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const closeDropdown = () => {
    setIsDropdownOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        closeDropdown();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  const user = useSelector((state) => state.user);

  return (
    <SocketContextProvider>
      {!isUserLogin && !isEmployerLogin ? (
        <header className="bg-oppty-bg p-10 w-full">
          <div className="container-2xl flex items-center justify-between">
            <div className="text-white text-lg font-bold flex flex-row items-center">
              <img src={logo} alt="Logo" className="w-36 lg:w-[200px] lg:mt-[-10px]" onClick={() => navigate('/')} />

              <nav className="hidden xl:flex flex-row gap-x-8 justify-center items-start mx-14">
                <a href="#" className="text-oppty-black text-lg-header font-semibold">
                  {lang.generalAbout}
                </a>
                <a href="/search-jobs" className="text-oppty-black text-lg-header font-semibold">
                  {lang.generalJobs}
                </a>
                <a href="/companies" className="text-oppty-black text-lg-header font-semibold">
                  {lang.generalCompanies}
                </a>
                <a href="/articles" className="text-oppty-black text-lg-header font-semibold">
                  {lang.generalArticles}
                </a>
              </nav>
            </div>

            <nav className="hidden xl:flex space-x-5 justify-center items-center">
              {/* Language Dropdown */}
              <select onChange={handleLanguageChange} value={localLang} className="text-black/50 text-base">
                <option value={0}>English</option>
                <option value={1}>中文</option>
                <option value={2}>Melayu</option>
              </select>
              {pathname === '/employer-landing' ? (
                <a
                  href="/"
                  className="text-white text-base font-semibold bg-oppty-green-1 w-[135px] py-2 text-center hover:bg-oppty-green-1/90 rounded-md"
                >
                  {lang.generalForCandidates}
                </a>
              ) : (
                <a
                  href="/employer-landing"
                  className="text-white text-base font-semibold bg-oppty-blue w-[135px] py-2 text-center hover:bg-oppty-blue/90 rounded-md"
                >
                  {lang.generalForEmployers}
                </a>
              )}
              <a
                href={pathname === '/employer-landing' ? '/employer-login' : '/candidate-login'}
                className="text-oppty-black text-base border-[1px] px-3 py-1.5 border-black hover:bg-black/40 hover:text-white font-bold hover:border-transparent"
              >
                {lang.generalLogin}
              </a>
              <a
                href={pathname === '/employer-landing' ? '/employer-sign-up' : '/candidate-sign-up'}
                className="w-[164px] h-[56px] flex-shrink-0 rounded-[7px] border border-solid border-black bg-oppty-yellow shadow-oppty-header-btn text-lg font-normal flex items-center justify-center"
              >
                {lang.generalGetStarted}
              </a>
            </nav>

            {/* Mobile Navigation */}
            <div className="xl:hidden relative space-x-6">
              <select onChange={handleLanguageChange} value={localLang} className="text-black/50 text-base">
                <option value={0}>English</option>
                <option value={1}>中文</option>
                <option value={2}>Melayu</option>
              </select>
              <button className="text-black text-3xl" onClick={() => setIsMobileNavOpen(!isMobileNavOpen)}>
                <span>&#9776;</span>
              </button>
              {isMobileNavOpen && (
                <div className="absolute top-12 right-0 bg-white p-4 shadow-md transition-opacity duration-1000 opacity-100 z-50">
                  <div className="flex flex-col items-center space-y-4">
                    <a
                      href={pathname === '/employer-landing' ? '/employer-sign-up' : '/candidate-sign-up'}
                      className="w-[164px] h-[56px] flex-shrink-0 rounded-[7px] border border-solid border-black bg-oppty-yellow shadow-oppty-header-btn text-lg font-normal flex items-center justify-center"
                    >
                      {lang.generalGetStarted}
                    </a>
                    <a
                      href={pathname === '/employer-landing' ? '/employer-login' : '/candidate-login'}
                      className="text-oppty-black text-base font-normal"
                    >
                      {lang.generalLogin}
                    </a>
                    {pathname === '/employer-landing' ? (
                      <a href="/" className="text-oppty-blue text-base font-normal">
                        {lang.generalForCandidates}
                      </a>
                    ) : (
                      <a href="/employer-landing" className="text-oppty-blue text-base font-normal">
                        {lang.generalForEmployers}
                      </a>
                    )}
                    <a href="#" className="text-oppty-black text-lg-header font-semibold">
                      {lang.generalAbout}
                    </a>
                    <a href="/search-jobs" className="text-oppty-black text-lg-header font-semibold">
                      {lang.generalJobs}
                    </a>
                    <a href="#" className="text-oppty-black text-lg-header font-semibold">
                      {lang.generalCompanies}
                    </a>
                    <a href="#" className="text-oppty-black text-lg-header font-semibold">
                      {lang.generalTools}
                    </a>
                  </div>
                </div>
              )}
            </div>
          </div>
        </header>
      ) : (
        <header className="bg-oppty-bg px-2 md:px-10 py-4 md:py-8 border-solid border-2 w-full">
          <div className="container-2xl flex items-center justify-between">
            <div className="text-white text-lg font-bold flex flex-row items-center">
              <img
                src={logo}
                alt="Logo"
                className="w-24 lg:w-[110px] lg:mt-[-10px] cursor-default"
                onClick={() => navigate('/search-jobs')}
              />

              <nav className="hidden xl:flex flex-row gap-x-8 justify-center items-start mx-14">
                {/* <a href="#" className="text-oppty-black text-base font-normal font-nunito tracking-[0.1px]">
                  {lang.generalMyJobs}
                </a> */}
                <a href="/search-jobs" className="text-oppty-black text-base font-normal font-nunito tracking-[0.1px]">
                  {lang.generalSearchJobs}
                </a>
                <a href="/companies" className="text-oppty-black text-base font-normal font-nunito tracking-[0.1px]">
                  {lang.generalCompanies}
                </a>
                {!isEmployerLogin && (
                  <a href="/articles" className="text-oppty-black text-base font-normal font-nunito tracking-[0.1px]">
                    {lang.generalArticles}
                  </a>
                )}
              </nav>
            </div>

            {/* <nav className="hidden xl:flex space-x-10 justify-center items-center">
              <select onChange={handleLanguageChange} value={localLang} className="text-black/50 text-sm">
                <option value={0}>English</option>
                <option value={1}>中文</option>
                <option value={2}>Melayu</option>
              </select>
              {!isEmployerLogin && (
                <div className="relative" ref={dropdownRef}>
                  <div className="relative cursor-pointer" onClick={toggleDropdown}>
                    <img src={notification} alt="notification" />
                    {unreadCount > 0 && (
                      <span className="absolute -top-2 left-2 inline-flex items-center justify-center px-1.5 py-1 text-xs font-bold leading-none text-red-100 bg-red-600 rounded-full">
                        {unreadCount}
                      </span>
                    )}
                  </div>
                  {isDropdownOpen && (
                    <div className="absolute right-0 mt-2">
                      <Notifications onClose={closeDropdown} />
                    </div>
                  )}
                </div>
              )}

              <div className="flex gap-x-3">
                {isUserLogin ? (
                  <img
                    src={user?.avatar ? user.avatar : defaultAvatar}
                    alt="userPic"
                    className="w-10 h-10 rounded-full object-cover"
                  />
                ) : (
                  <img
                    src={employer?.companyLogo ? employer.companyLogo : defaultAvatar}
                    alt="userPic"
                    className="w-10 h-10 rounded-full object-cover"
                  />
                )}
                <HeaderProfileDropdownMenu />
              </div>
            </nav> */}

            {/* Mobile Navigation */}
            <div className="relative gap-x-4 xl:gap-x-8 flex items-center">
              <select
                onChange={handleLanguageChange}
                value={localLang}
                // className="text-black/50 text-sm w-14 custom-select"
                className="flex xl:hidden appearance-none bg-transparent text-black/50 text-sm w-16 h-full py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                style={{
                  paddingRight: '0.5rem'
                }}
              >
                <option value={0}>En</option>
                <option value={1}>中文</option>
                <option value={2}>My</option>
              </select>
              <select
                onChange={handleLanguageChange}
                value={localLang}
                className="text-black/50 text-sm hidden xl:flex"
              >
                <option value={0}>English</option>
                <option value={1}>中文</option>
                <option value={2}>Melayu</option>
              </select>
              {process.env.REACT_APP_ENVIRONMENT === 'PROD' && !isEmployerLogin && (
                <div
                  title="Marketplace"
                  onClick={() =>
                    window.open(`https://marketplace.oppty.ai/auth/${localStorage.getItem('token')}`,'_blank','noopener,noreferrer')}
                  className="relative inline-block cursor-pointer"
                >
                  <BuildingStorefrontIcon color="#4864E1" className="h-7 w-7" aria-hidden="true" title="Marketplace" />
                  <span className="absolute -top-2 -right-4 inline-flex items-center justify-center px-1 py-1 text-[10px] md:text-xs font-bold leading-none text-white bg-red-600 rounded-full">
                    New
                  </span>
                </div>
              )}
              {/* <img src={message} alt="message" /> */}
              <div className="relative" ref={dropdownRef}>
                <div className="relative cursor-pointer" onClick={toggleDropdown}>
                  <img src={notification} alt="notification" />
                  {unreadCount > 0 && (
                    <span className="absolute -top-2 left-2 inline-flex items-center justify-center px-1.5 py-1 text-xs font-bold leading-none text-red-100 bg-red-600 rounded-full">
                      {unreadCount}
                    </span>
                  )}
                </div>
                {isDropdownOpen && (
                  <div className="absolute right-0 mt-2">
                    <Notifications onClose={closeDropdown} />
                  </div>
                )}
              </div>
              <div className="flex">
                {isEmployerLogin ? (
                  <img
                    src={employer?.companyLogo ? employer?.companyLogo : defaultAvatar}
                    alt="userPic"
                    className="w-10 h-10 rounded-full object-cover"
                  />
                ) : (
                  <img
                    src={user?.avatar ? user.avatar : defaultAvatar}
                    alt="userPic"
                    className="w-10 h-10 rounded-full object-cover"
                  />
                )}
                <HeaderProfileDropdownMenu />
              </div>
            </div>
          </div>
        </header>
      )}
    </SocketContextProvider>
  );
};

export default Header;
