import {
  FETCH_ADMIN_LOGIN,
  FETCH_ADMIN_LOGOUT,
  FETCH_ADMIN_DASHBOARD,
  FETCH_ALL_CANDIDATES,
  FETCH_SEARCH_CANDIDATE,
  FETCH_ALL_EMPLOYERS,
  FETCH_SEARCH_EMPLOYER,
  FETCH_ALL_JOBS,
} from '../actions/adminAction';

const initialLoginState = false;

const initialState = {
  users: [],
  searchCandidate: {},
  employers: [],
  searchEmployer: [],
  dashboardData: [],
  jobs: [],
};

const adminReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ADMIN_DASHBOARD:
      return {
        ...state,
        dashboardData: action.payload?.adminDashboardData
      };
    case FETCH_ALL_CANDIDATES:
      return {
        ...state,
        users: action.payload?.users
      };
    case FETCH_SEARCH_CANDIDATE:
      return {
        ...state,
        searchCandidate: action.payload?.user
      };
    case FETCH_ALL_EMPLOYERS:
      return {
        ...state,
        employers: action.payload?.employers
      };
    case FETCH_SEARCH_EMPLOYER:
      return {
        ...state,
        searchEmployer: action.payload?.employer
      };
      case FETCH_ALL_JOBS:
      return {
        ...state,
        jobs: action.payload?.jobs
      };
    default:
      return state;
  }
};

export default adminReducer;

export const adminLoginReducer = (state = initialLoginState, action) => {
  switch (action.type) {
    case FETCH_ADMIN_LOGIN:
      return true;

    case FETCH_ADMIN_LOGOUT:
      return false;

    default:
      return state;
  }
};
