import { useDispatch, useSelector } from 'react-redux';
import { DocumentIcon } from '@heroicons/react/24/outline';
import TestimonialCard from '../../../common/testimonialCard';
import { testimonialDelete } from '../../../actions/userAction';
import { copyToClipboard } from '../../../utils/functions';

const linkUrl = process.env.REACT_APP_LINKURL;

const Testimonial = () => {
  const dispatch = useDispatch();

  const lang = useSelector((state) => state.language.lang);
  const user = useSelector((state) => state.user);

  const handleDeleteTestimonial = (id) => {
    dispatch(testimonialDelete({ _id: id }));
  };

  return (
    <div
      id="uploadResume"
      className="flex flex-col justify-center py-5 lg:py-10 px-2 mx-2 lg:px-16 lg:mx-16 rounded-[20px] bg-white"
    >
      <div className="flex flex-row items-center gap-x-2 lg:gap-x-5">
        <div className="flex justify-center w-14 h-14 p-4 rounded-full bg-oppty-blue-5">
          <DocumentIcon className="h-full w-full stroke-blue-700" />
        </div>
        <div className="text-lg lg:text-2xl font-raleway font-bold leading-normal uppercase  text-black pr-5">
          {lang.profileTestimonial}
        </div>
        <div className="flex-grow border-t-[1px] border-oppty-grey-15"></div>
        <button
          onClick={() => copyToClipboard(`${linkUrl}/testimonial/${user?.publicId}`, 'URL Copied')}
          className="text-base font-nunito font-bold leading-normal bg-black text-white px-6 py-1 rounded-md"
        >
          {lang.profileCopyUrl}
        </button>
      </div>
      <div className="flex flex-col justify-center py-3 pl-0 md:pl-16">
        <div className="text-sm lg:-mt-5 font-semibold italic">*{lang.profileClickOnCopyURL}</div>
        <div className="flex flex-col gap-5 gap-y-2 pt-4">
          {user?.testimonial?.map((item, index) => (
            <TestimonialCard onDelete={() => handleDeleteTestimonial(item?._id)} testimonial={item} key={index} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Testimonial;
