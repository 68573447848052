import { DocumentIcon } from '@heroicons/react/24/outline';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import PsychometricHeader from './psychometricHeader';
import PsychometricCareer from './psychometricCareer';

const Psychometric = () => {
  const lang = useSelector((state) => state.language.lang);
  const user = useSelector((state) => state.user);

  const navigate = useNavigate();

  return (
    <div
      id="psychometric"
      className="flex flex-col justify-center py-5 lg:py-10 px-2 mx-2 lg:px-16 lg:mx-16 rounded-[20px] bg-white"
    >
      <div className="flex flex-row items-center gap-x-2 lg:gap-x-5">
        <div className="flex justify-center w-14 h-14 p-4 rounded-full bg-oppty-blue-5">
          <DocumentIcon className="h-full w-full stroke-blue-700" />
        </div>
        <div className="text-lg lg:text-2xl font-raleway font-bold leading-normal uppercase  text-black pr-5">
          {lang.generalCompatibilityTest}
        </div>
        <div className="flex-grow border-t-[1px] border-oppty-grey-15"></div>
        {(user?.psychometric?.attempts < 3 || !user?.psychometric) && (
          <button
            onClick={() => navigate('/profile/psychometric')}
            className="text-base font-nunito font-bold leading-normal bg-black text-white px-1 lg:px-6 py-1 rounded-md"
          >
            {user?.psychometric?.attempts > 0 ? lang.generalReTakeTest : lang.generalTakeTest}
          </button>
        )}
      </div>
      <div className="flex flex-col justify-center py-3 pl-0 md:pl-16">
        <div className="text-sm text-gray-500 font-bold -mt-2 lg:-mt-6 mb-3 pl-3">
          {user?.psychometric?.attempts || 0} out of 3 attempts
        </div>

        {user?.psychometric && <PsychometricHeader psychometric={user?.psychometric} />}
        {user?.psychometric && <PsychometricCareer psychometric={user?.psychometric} />}
      </div>
    </div>
  );
};

export default Psychometric;
