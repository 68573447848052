import { Checkbox } from '@material-tailwind/react';
import profileEdit from '../assets/icons/pencil.svg';
import profileDelete from '../assets/icons/trashCan.svg';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { monthOptions, yearOptions } from '../constant/dropdownConstOptions';
import Select from 'react-select';
import { calculateDateDuration } from '../utils/functions';
import { candidateWorkExperienceSchema, validateDates } from '../validations/validationSchemas';
import countryOptions from '../constant/countries.json';
import AsyncSelect from 'react-select/async';
import ReactQuill from 'react-quill';

const ProfileExperienceCard = ({ experience, uniqueKey, onDelete, onEdit }) => {
  console.log('exp inside: ', experience);
  const getCurrentValue = () => {
    if (experience.endMonth === '99' && experience.endYear === 99) {
      return true;
    } else {
      return false;
    }
  };

  const [isEditMode, setIsEditMode] = useState(false);
  const [editedExperience, setEditedExperience] = useState({ ...experience });
  const [current, setCurrent] = useState(getCurrentValue());
  const [errors, setErrors] = useState({});

  const lang = useSelector((state) => state.language.lang);

  const handleCurrentChange = () => {
    setCurrent(!current);
    if (!current) {
      setEditedExperience((prevState) => ({
        ...prevState,
        endMonth: '99',
        endYear: '99'
      }));
    } else {
      setEditedExperience((prevState) => ({
        ...prevState,
        endMonth: 'January',
        endYear: new Date().getFullYear()
      }));
    }
  };
  const handleEditClick = () => {
    setIsEditMode(true);
  };

  const handleSaveClick = async () => {
    try {
      await candidateWorkExperienceSchema.validate(editedExperience, { abortEarly: false });

      const dateErrors = validateDates(editedExperience);

      if (Object.keys(dateErrors).length > 0) {
        setErrors(dateErrors);
        return;
      }

      const response = await onEdit(editedExperience);
      if (response && !response.error) {
        setIsEditMode(false);
        setErrors({});
      }
    } catch (validationError) {
      if (validationError.inner) {
        const formErrors = validationError.inner.reduce((acc, error) => {
          return { ...acc, [error.path]: error.message };
        }, {});

        setErrors(formErrors);
      } else {
        console.error('Validation failed:', validationError);
      }
    }
  };
  // const handleSaveClick = () => {
  //   onEdit(editedExperience)
  //     .then((response) => {
  //       if (response && !response.error) {
  //         setIsEditMode(false);
  //       }
  //     })
  //     .catch((error) => {
  //       console.error('API call failed:', error);
  //     });
  // };

  const handleCancelClick = () => {
    setEditedExperience({ ...experience });
    setIsEditMode(false);
    setCurrent(getCurrentValue());
    setErrors({});
  };

  const handleChange = (name, value) => {
    setEditedExperience((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const dateDuration = calculateDateDuration(
    experience.startYear,
    experience.startMonth,
    experience.endYear,
    experience.endMonth
  );

  const countryLoadOptions = (inputValue, callback) => {
    const filteredOptions = countryOptions
      .filter((option) => option.label.toLowerCase().includes(inputValue.toLowerCase()))
      .slice(0, 20);

    callback(filteredOptions);
  };

  return (
    <div className="border rounded-md border-gray-300 bg-oppty-grey-17">
      {isEditMode ? (
        <form>
          <div className="flex flex-col p-5">
            <div className="flex flex-col gap-y-8">
              <div className="flex flex-col lg:flex-row gap-x-10 gap-y-5">
                <div className="w-full lg:w-1/2 flex flex-col gap-y-2">
                  <div className="text-base font-nunito font-bold leading-normal text-oppty-grey-16">
                    {lang.createCompanyName}
                  </div>
                  <input
                    type="text"
                    id="companyName"
                    name="companyName"
                    value={editedExperience.companyName}
                    onChange={(e) => handleChange('companyName', e.target.value)}
                    className="py-4 px-5 leading-tight border focus:outline rounded-md border-gray-300"
                  />
                  {errors.companyName && <div className="text-red-500 text-sm">{errors.companyName}</div>}
                </div>
                <div className="w-full lg:w-1/2 flex flex-col gap-y-2">
                  <div className="text-base font-nunito font-bold leading-normal text-oppty-grey-16">
                    {lang.generalPosition}
                  </div>
                  <input
                    type="text"
                    id="position"
                    name="position"
                    value={editedExperience.position}
                    onChange={(e) => handleChange('position', e.target.value)}
                    className="py-4 px-5 leading-tight border focus:outline rounded-md border-gray-300"
                  />
                  {errors.position && <div className="text-red-500 text-sm">{errors.position}</div>}
                </div>
              </div>
              <div className="w-full flex flex-col lg:flex-row gap-y-2 gap-x-10">
                <div className="w-full lg:w-1/2 flex flex-col gap-y-2">
                  <div className="text-base font-nunito font-bold leading-normal text-oppty-grey-16">
                    {lang.profileStartDate}
                  </div>
                  <div className="flex flex-row gap-x-2">
                    <div className="flex flex-col w-full lg:w-1/2">
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        defaultValue={'Month'}
                        value={monthOptions.find((option) => option.value === editedExperience.startMonth)}
                        onChange={(selectedOption) => handleChange('startMonth', selectedOption?.value || '')}
                        placeholder="Month"
                        isDisabled={false}
                        isLoading={false}
                        isClearable={true}
                        isRtl={false}
                        isSearchable={true}
                        name="startMonth"
                        options={monthOptions}
                        styles={{
                          control: (provided) => ({
                            ...provided,
                            height: 54
                          })
                        }}
                      />
                      {errors.startMonth && <div className="text-red-500 text-sm">{errors.startMonth}</div>}
                    </div>
                    <div className="flex flex-col w-full lg:w-1/2">
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        defaultValue={'Year'}
                        value={yearOptions.find((option) => option.value === editedExperience.startYear)}
                        onChange={(selectedOption) => handleChange('startYear', selectedOption?.value || '')}
                        placeholder="Year"
                        isDisabled={false}
                        isLoading={false}
                        isClearable={true}
                        isRtl={false}
                        isSearchable={true}
                        name="startYear"
                        options={yearOptions}
                        styles={{
                          control: (provided) => ({
                            ...provided,
                            height: 54
                          })
                        }}
                      />
                      {errors.startYear && <div className="text-red-500 text-sm">{errors.startYear}</div>}
                    </div>
                  </div>
                </div>
                {!current && (
                  <div className="w-full lg:w-1/2 flex flex-col gap-y-2">
                    <div className="text-base font-nunito font-bold leading-normal text-oppty-grey-16">
                      {lang.profileEndDate}
                    </div>
                    <div className="flex flex-row gap-x-2">
                      <div className="flex flex-col w-1/2">
                        <Select
                          className="basic-single"
                          classNamePrefix="select"
                          defaultValue={'Month'}
                          value={monthOptions.find((option) => option.value === editedExperience.endMonth)}
                          onChange={(selectedOption) => handleChange('endMonth', selectedOption?.value || '')}
                          placeholder="Month"
                          isDisabled={false}
                          isLoading={false}
                          isClearable={true}
                          isRtl={false}
                          isSearchable={true}
                          id="endMonth"
                          name="endMonth"
                          options={monthOptions}
                          styles={{
                            control: (provided) => ({
                              ...provided,
                              height: 54
                            })
                          }}
                        />
                        {errors.endMonth && <div className="text-red-500 text-sm">{errors.endMonth}</div>}
                      </div>
                      <div className="flex flex-col w-1/2">
                        <Select
                          className="basic-single"
                          classNamePrefix="select"
                          defaultValue={'Year'}
                          value={yearOptions.find((option) => option.value === editedExperience.endYear)}
                          onChange={(selectedOption) => handleChange('endYear', selectedOption?.value || '')}
                          placeholder="Year"
                          isDisabled={false}
                          isLoading={false}
                          isClearable={true}
                          isRtl={false}
                          isSearchable={true}
                          name="endYear"
                          options={yearOptions}
                          styles={{
                            control: (provided) => ({
                              ...provided,
                              height: 54
                            })
                          }}
                        />
                        {errors.endYear && <div className="text-red-500 text-sm">{errors.endYear}</div>}
                      </div>
                    </div>
                    {errors.dates && <div className="text-red-500 text-sm">{errors.dates}</div>}
                  </div>
                )}
              </div>
              <div className="w-full flex flex-col lg:flex-row gap-y-2 gap-x-10">
                <div className="w-full lg:w-1/2 flex flex-col gap-y-2">
                  <div className="text-base font-nunito font-bold leading-normal text-oppty-grey-16">
                    {lang.profileCountry}
                  </div>
                  <AsyncSelect
                    cacheOptions
                    defaultOptions
                    value={countryOptions.find((option) => option.value === editedExperience.country)}
                    onChange={(selectedOption) => {
                      const selectedValue = selectedOption || '';
                      if (selectedValue) handleChange('country', selectedValue.value);
                    }}
                    loadOptions={countryLoadOptions}
                    placeholder={lang.generalSearch}
                    isDisabled={false}
                    isClearable={true}
                    isSearchable={true}
                    name="countries"
                    id="countries"
                    menuPlacement="bottom"
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        height: 54
                      }),
                      input: (provided) => ({
                        ...provided,
                        boxShadow: 'none',
                        '& input': {
                          boxShadow: 'none !important',
                          outline: 'none !important'
                        }
                      })
                    }}
                    className="w-full"
                  />
                  {errors.country && <div className="text-red-500 text-sm">{errors.country}</div>}
                </div>
                <div className="w-full lg:w-1/2 flex flex-col gap-y-2"></div>
              </div>
              <div className="w-full flex flex-col gap-y-2">
                <div className="text-base font-nunito font-bold leading-normal text-oppty-grey-16">
                  {lang.generalJobDescription}
                </div>
                <ReactQuill
                  theme="snow"
                  value={editedExperience?.description}
                  onChange={(e) => handleChange('description', e)}
                  className="bg-white"
                />
                {errors.description && <div className="text-red-500 text-sm">{errors.description}</div>}
              </div>
              <div className="flex flex-row mt-[-20px] items-center">
                <Checkbox onChange={handleCurrentChange} value={current} checked={current} />
                <div className="text-base font-nunito font-bold leading-normal text-oppty-grey-16">
                  {lang.profileCurrent}
                </div>
              </div>
              <div className="flex justify-end flex-row gap-x-2 mt-[-20px] pb-4">
                <button
                  onClick={handleCancelClick}
                  className="text-base font-nunito font-semibold leading-normal bg-oppty-grey-14 text-oppty-grey-13 px-8 py-3 rounded-md"
                >
                  {lang.profileCancel}
                </button>
                <button
                  type="button"
                  onClick={handleSaveClick}
                  className="text-base font-nunito font-semibold leading-normal bg-oppty-blue text-white px-8 py-3 rounded-md"
                >
                  {lang.profileSave}
                </button>
              </div>
            </div>
          </div>
        </form>
      ) : (
        <div key={uniqueKey} className="flex flex-row gap-x-5 p-3 relative">
          <div className="w-full flex flex-col gap-y-4">
            <div className="w-full flex flex-col lg:flex-row justify-between gap-2">
              <div>
                <div className="text-xl font-nunito font-bold leading-normal text-black">{experience.position}</div>
                <div className="text-base font-nunito font-normal leading-normal text-black">
                  {experience.companyName}
                </div>
                <div className="text-base font-nunito font-semibold leading-normal text-gray-500">
                  {experience.country}
                </div>
              </div>
              <div className="flex justify-between lg:justify-start gap-x-5 flex-shrink-0">
                <div className="">
                  <div className="text-sm font-nunito font-normal leading-normal text-black">
                    {experience.startMonth} {experience.startYear} -{' '}
                    {experience.endMonth === '99' && experience.endYear === 99
                      ? 'Current'
                      : `${experience.endMonth} ${experience.endYear}`}
                  </div>
                  <div className="text-sm font-nunito font-normal leading-normal text-black">
                    {dateDuration.years > 0
                      ? dateDuration.years === 1
                        ? `${dateDuration.years} year`
                        : `${dateDuration.years} years`
                      : ''}{' '}
                    {dateDuration.months > 1 ? `${dateDuration.months} months` : `${dateDuration.months} month`}
                  </div>
                </div>
                <div className="flex gap-x-1 right-5 top-1">
                  <button
                    onClick={handleEditClick}
                    className="flex justify-center w-8 h-8 p-2 rounded-md hover:bg-green-400"
                  >
                    <img src={profileEdit} alt="profile icon" className="h-full w-full" />
                  </button>
                  <button onClick={onDelete} className="flex justify-center w-8 h-8 p-2 rounded-md hover:bg-red-600">
                    <img src={profileDelete} alt="profile icon" className="h-full w-full" />
                  </button>
                </div>
              </div>
            </div>
            <div className="flex flex-wrap justify-between items-start">
              <div className="w-full">
                <div className="ml-2 break-words" dangerouslySetInnerHTML={{ __html: experience?.description }}></div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProfileExperienceCard;
