import PortfolioCard from './portfolioCard';

const Portfolio = ({ user }) => {
  return (
    <div className="w-full flex flex-col p-10 lg:p-16">
      <div className="text-5xl font-bold mb-16 text-white">My Portfolio</div>
      <div className="flex flex-wrap w-full gap-5 justify-center bg-template9-orange-1 py-10">
        {user?.portfolios?.map((portfolio, index) => {
          return <PortfolioCard key={index} portfolio={portfolio} />;
        })}
      </div>
    </div>
  );
};

export default Portfolio;
