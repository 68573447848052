import React, { useEffect, useState } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/24/solid';
import { createPortal } from 'react-dom';

const DropdownMenuLang = ({ options, onSelect, placeholder, icon, resetValue }) => {
  const localLang = localStorage.getItem('lan');

  const [selectedOption, setSelectedOption] = useState({ value: null, label: null });
  const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0, width: 0 });
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    if (resetValue) {
      setSelectedOption({ value: null, label: null });
    }
  }, [resetValue]);

  const handleItemClick = (value, label) => {
    setSelectedOption({ value, label });
    setIsMenuOpen(false);
    onSelect && onSelect({ value, label });
  };

  const openMenu = (e) => {
    const buttonRect = e.target.getBoundingClientRect();
    setDropdownPosition({
      top: buttonRect.bottom + window.scrollY,
      left: buttonRect.left + window.scrollX,
      width: buttonRect.width
    });
    setIsMenuOpen(true);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  return (
    <Menu as="div" className="relative inline-block text-left w-full">
      <Menu.Button
        className="flex w-full justify-center items-center py-2 px-6 gap-x-5 rounded-md border border-gray-300 text-gray-700 hover:bg-gray-50"
        onClick={openMenu}
      >
        {icon ? <img src={icon} alt="icon" /> : ''}
        {selectedOption?.label?.[localLang] || placeholder}
        <ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-400 pointer-events-none" aria-hidden="true" />
      </Menu.Button>

      {isMenuOpen &&
        createPortal(
          <div
            style={{
              position: 'absolute',
              top: dropdownPosition.top,
              left: dropdownPosition.left,
              width: dropdownPosition.width,
              zIndex: 1000
            }}
            className="bg-white shadow-lg rounded-md max-h-60 overflow-y-auto mt-2"
          >
            <Transition
              as={React.Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
              afterLeave={closeMenu}
            >
              <Menu.Items static>
                {options.map((option, index) => (
                  <div key={index} className="py-1">
                    <Menu.Item>
                      {({ active }) => (
                        <div
                          className={`${active ? 'bg-gray-100 text-gray-900' : 'text-gray-700'} block px-4 py-2 text-sm`}
                          onClick={() => handleItemClick(option.value, option.label)}
                        >
                          {option.label[localLang]}
                        </div>
                      )}
                    </Menu.Item>
                  </div>
                ))}
              </Menu.Items>
            </Transition>
          </div>,
          document.body
        )}
    </Menu>
  );
};

export default DropdownMenuLang;

// import React, { useEffect, useState } from 'react';
// import { Menu, Transition } from '@headlessui/react';
// import { ChevronDownIcon } from '@heroicons/react/24/solid';

// const DropdownMenuLang = ({ options, onSelect, placeholder, icon, resetValue }) => {
//   const localLang = localStorage.getItem('lan');

//   const [selectedOption, setSelectedOption] = useState({ value: null, label: null });

//   useEffect(() => {
//     if (resetValue) {
//       setSelectedOption({ value: null, label: null });
//     }
//   }, [resetValue]);

//   const handleItemClick = (value, label) => {
//     setSelectedOption({ value, label });
//     onSelect && onSelect({ value, label });
//   };

//   return (
//     <Menu as="div" className="relative inline-block text-left">
//       <div>
//         <Menu.Button className="flex w-full justify-center items-center py-2 px-6 gap-x-5 rounded-md border border-oppty-grey-6 text-oppty-grey-7 hover:bg-gray-50">
//           {icon ? <img src={icon} alt="icon" /> : ''}
//           {selectedOption?.label?.[localLang] || placeholder}
//           <ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
//         </Menu.Button>
//       </div>

//       <Transition
//         as={React.Fragment}
//         enter="transition ease-out duration-100"
//         enterFrom="transform opacity-0 scale-95"
//         enterTo="transform opacity-100 scale-100"
//         leave="transition ease-in duration-75"
//         leaveFrom="transform opacity-100 scale-100"
//         leaveTo="transform opacity-0 scale-95"
//       >
//         <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
//           {options.map((option, index) => (
//             <div key={index} className="py-1">
//               <Menu.Item>
//                 {({ active }) => (
//                   <div
//                     className={`${active ? 'bg-gray-100 text-gray-900' : 'text-gray-700'} block px-4 py-2 text-sm`}
//                     onClick={() => handleItemClick(option.value, option.label)}
//                   >
//                     {option.label[localLang]}
//                   </div>
//                 )}
//               </Menu.Item>
//             </div>
//           ))}
//         </Menu.Items>
//       </Transition>
//     </Menu>
//   );
// };

// export default DropdownMenuLang;
