import { useSelector } from 'react-redux';
import AvailableJobCard from './availableJobCard';

const AvailableJobs = () => {
  const lang = useSelector((state) => state.language.lang);
  const employer = useSelector((state) => state.selectedEmployer);

  console.log('employer: ', employer);

  return (
    <div className="flex flex-col justify-center py-10 px-5 lg:px-16">
      <div className="text-4xl font-nunito font-bold text-black/70 pb-8">{lang.companyAvailableJob}</div>
      <div className="flex flex-col gap-y-6">
        {employer?.employer?.jobs?.map((job) => {
          return <AvailableJobCard job={job} location={employer?.employer?.companyLocation} />;
        })}
      </div>
    </div>
  );
};

export default AvailableJobs;
