const PortfolioCard = ({ portfolio }) => {
  return (
    <div
      className="p-10 w-96 lg:w-[450px] cursor-pointer transform transition-transform duration-300 hover:scale-110 "
      onClick={() => window.open(portfolio?.link)}
    >
      <img src={portfolio.image} alt="pic" className="w-96 lg:w-[400px] h-56 object-cover" />
      <div className="text-black font-bold pt-5 text-xl">{portfolio?.highlight}</div>
    </div>
  );
};

export default PortfolioCard;
