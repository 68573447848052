import Certification from './certification';
import Education from './education';
import HeaderSection from './headerSection';
import Portfolio from './portfolio';
import Psychometrics from './psychometrics';
import Testimonial from './testimonial';
import Video from './video';
import WorkExperience from './workExperience';

const Template3 = ({ user }) => {
  console.log('user: ', user);
  return (
    <div className="flex flex-col min-w-96 w-full h-full bg-template3-gray-1 font-raleway overflow-hidden p-3 lg:p-10 gap-y-5">
      <HeaderSection user={user} />
      {user?.workExperiences?.length > 0 && <WorkExperience user={user} />}
      {user?.educations?.length > 0 && <Education user={user} />}
      {user?.certifications?.length > 0 && <Certification user={user} />}
      {user?.portfolios?.length > 0 && <Portfolio user={user} />}
      {user?.testimonial?.length > 0 && <Testimonial user={user} />}
      {user?.psychometric?.showResult === 1 && <Psychometrics user={user} />}
      {user?.video && <Video user={user} />}
    </div>
  );
};

export default Template3;
