import { useDispatch, useSelector } from 'react-redux';
import profileLanguage from '../../../assets/icons/profileLanguage.svg';
import GeneralTile from '../../../common/generealTile';
import Select from 'react-select';
import languageOptions from '../../../constant/language.json';
import { useState } from 'react';
import { useFormik } from 'formik';
import { languageAdd, languageDelete } from '../../../actions/userAction';
import { proficiencyOptions } from '../../../constant/dropdownConstOptions';
import { getLabelByValue, getOptionsByLanguage } from '../../../utils/functions';
import { languageSchema } from '../../../validations/validationSchemas';
import { ToastBar } from '../../../common/toastbar';

const Languages = () => {
  const localLang = localStorage.getItem('lan');

  const dispatch = useDispatch();

  const [addLanguage, setAddLanguage] = useState(false);
  const lang = useSelector((state) => state.language.lang);
  const user = useSelector((state) => state.user);

  const formik = useFormik({
    initialValues: {
      lang: '',
      level: ''
    },
    validationSchema: languageSchema,
    onSubmit: async (values, { resetForm }) => {
      console.log('value: ', values);
      const languageExists = user?.languages?.some((language) => language.lang === values.lang);

      if (languageExists) {
        ToastBar.warning(['This language already added', '此语言已添加', 'Bahasa ini sudah ditambahkan'][localLang]);
      } else {
        dispatch(languageAdd(values));
        resetForm();
        setAddLanguage(false);
      }
    }
  });

  const handleDeleteLanguage = (index) => {
    const deleteLanguages = user.languages[index];

    console.log('deleteLanguages: ', deleteLanguages);
    dispatch(languageDelete(deleteLanguages));
  };

  // const handleDeleteLanguage = (index) => {
  //   const updatedLanguages = [...user.languages];
  //   updatedLanguages.splice(index, 1);

  //   console.log('updatedLanguages: ', updatedLanguages);
  //   dispatch(languageDelete(updatedLanguages));
  // };

  return (
    <div
      id="languages"
      className="flex flex-col justify-center py-5 lg:py-10 px-2 mx-2 lg:px-16 lg:mx-16 rounded-[20px] bg-white"
    >
      <div className="flex flex-row items-center gap-x-2 lg:gap-x-5">
        <div className="flex justify-center w-14 h-14 p-4 rounded-full bg-oppty-blue-5">
          <img src={profileLanguage} alt="profile icon" className="h-full w-full" />
        </div>
        <div className="text-lg lg:text-2xl font-raleway font-bold leading-normal uppercase  text-black pr-5">
          {lang.profileLanguages}
        </div>
        <div className="flex-grow border-t-[1px] border-oppty-grey-15"></div>
        {!addLanguage && (
          <button
            onClick={() => setAddLanguage(true)}
            className="text-base font-nunito font-bold leading-normal bg-black text-white px-6 py-1 rounded-md"
          >
            + {lang.profileAdd}
          </button>
        )}
      </div>
      <div className="flex flex-col justify-center py-3 pl-0 md:pl-16">
        <div className="flex flex-wrap gap-x-5 pb-7 gap-y-2">
          {user?.languages?.map((language, index) => {
            return (
              <GeneralTile
                name={language.lang}
                level={getLabelByValue(language.level, proficiencyOptions)}
                uniqueKey={index}
                key={index}
                onDelete={() => handleDeleteLanguage(index)}
              />
            );
          })}
        </div>
        {addLanguage && (
          <form onSubmit={formik.handleSubmit}>
            <div>
              <div className="flex-grow border-t-[1px] pt-3 border-oppty-grey-15"></div>
              <div className="flex flex-row gap-x-10 pt-5">
                <div className="w-1/2 flex flex-col gap-y-2">
                  <div className="text-base font-nunito font-bold leading-normal text-oppty-grey-16">
                    {lang.profileSelectLanguage}
                  </div>
                  <Select
                    className="basic-single"
                    classNamePrefix="select"
                    defaultValue={'Select Language'}
                    placeholder={lang.generalSearch}
                    value={languageOptions.find((option) => option.value === formik.values.lang)}
                    onChange={(selectedOption) => formik.setFieldValue('lang', selectedOption?.value || '')}
                    isDisabled={false}
                    isLoading={false}
                    isClearable={true}
                    isRtl={false}
                    isSearchable={true}
                    name="language"
                    options={languageOptions}
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        height: 54
                      }),
                      input: (provided) => ({
                        ...provided,
                        boxShadow: 'none',
                        '& input': {
                          boxShadow: 'none !important',
                          outline: 'none !important'
                        }
                      })
                    }}
                  />
                  {formik.touched.lang && formik.errors.lang ? (
                    <div className="text-red-500 text-sm">{formik.errors.lang}</div>
                  ) : null}
                </div>
                <div className="w-1/2 flex flex-col gap-y-2">
                  <div className="text-base font-nunito font-bold leading-normal text-oppty-grey-16">
                    {lang.profileLevelOfPro}
                  </div>
                  <Select
                    className="basic-single"
                    classNamePrefix="select"
                    defaultValue={'Select Proficiency'}
                    placeholder={lang.profileSelect}
                    value={proficiencyOptions.find((option) => option.value === formik.values.level?.[localLang])}
                    onChange={(selectedOption) => formik.setFieldValue('level', selectedOption?.value || '')}
                    isDisabled={false}
                    isLoading={false}
                    isClearable={false}
                    isRtl={false}
                    isSearchable={false}
                    name="proficiency"
                    options={getOptionsByLanguage(proficiencyOptions)}
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        height: 54
                      }),
                      input: (provided) => ({
                        ...provided,
                        boxShadow: 'none',
                        '& input': {
                          boxShadow: 'none !important',
                          outline: 'none !important'
                        }
                      })
                    }}
                  />
                  {formik.touched.level && formik.errors.level ? (
                    <div className="text-red-500 text-sm">{formik.errors.level}</div>
                  ) : null}
                </div>
              </div>
              <div className="flex justify-end flex-row gap-x-2 pt-10 pb-5">
                <button
                  onClick={() => setAddLanguage(false)}
                  className="text-base font-nunito font-semibold leading-normal bg-oppty-grey-14 text-oppty-grey-13 px-8 py-3 rounded-md"
                >
                  {lang.profileCancel}
                </button>
                <button
                  type="submit"
                  className="text-base font-nunito font-semibold leading-normal bg-oppty-blue text-white px-8 py-3 rounded-md"
                >
                  {lang.profileAdd}
                </button>
              </div>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default Languages;
