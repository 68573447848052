import {
  FETCH_EMPLOYER_PRE_REG_COMPLETE,
  FETCH_EMPLOYER_PRE_REG_DETAIL,
  FETCH_EMPLOYER_PRE_REG_ERROR,
  FETCH_EMPLOYER_PRE_REG_SUCCESS
} from '../actions/employerPreRegisterAction'

const initialState = {
  companyName: '',
  email: '',
  password: '',
  otpData: '',
  error: ''
}

const employerPreRegisterReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_EMPLOYER_PRE_REG_SUCCESS: {
      return {
        ...state,
        otpData: action.payload?.otpData
      }
    }

    case FETCH_EMPLOYER_PRE_REG_DETAIL:
      return {
        ...state,
        companyName: action.payload?.companyName,
        email: action.payload?.email,
        password: action.payload?.password
      }

    case FETCH_EMPLOYER_PRE_REG_COMPLETE:
      return {
        ...state,
        companyName: '',
        email: '',
        password: ''
      }

    case FETCH_EMPLOYER_PRE_REG_ERROR:
      return {
        ...state,
        error: action.payload?.error
      }

    default:
      return state
  }
}

export default employerPreRegisterReducer;
