import { ReactComponent as Quotation } from '../../../assets/icons/quotation2.svg';

const TestimonialCard = ({ testimonial }) => {
  return (
    <div className="px-8 pb-8 py-14 w-96 h-[313px] shadow-lg rounded-lg bg-white overflow-hidden">
      <div className="h-36 relative">
        <div className='font-semibold'>{testimonial?.testimonial}</div>
        <div className="w-16 h-16 absolute -top-12 -left-5">
          <Quotation style={{ color: '#011437' }} />
        </div>
      </div>
      <div className="h-16 -m-8 mt-12 flex justify-center items-center relative">
        <div className="absolute top-0 px-8 text-sm text-center z-10">
          <span className="text-black font-bold text-2xl">
            {testimonial?.salutation} {testimonial?.fullName}
          </span>
          <div className="text-black">
            <span className="font-semibold">{testimonial?.position}</span> at{' '}
            <span className="italic font-semibold">{testimonial?.organization}</span>
          </div>
        </div>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 600" className="absolute -bottom-1">
          <path
            fill="#FFB300"
            fillOpacity="1"
            d="M0,160L80,176C160,192,320,224,480,218.7C640,213,800,171,960,149.3C1120,128,1280,128,1360,128L1440,128L1440,600L1360,600C1280,600,1120,600,960,600C800,600,640,600,480,600C320,600,160,600,80,600L0,600Z"
          ></path>
        </svg>
      </div>
    </div>
  );
};

export default TestimonialCard;
