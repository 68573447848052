import WorkExperienceCard from './workExperienceCard';
import bgImage from '../../../assets/templates/template6/template6bg.jpeg';

const WorkExperience = ({ user }) => {
  return (
    <div className="w-full flex flex-col shadow-md bg-white border-r-[16px] border-template6-green-1">
    <div className="flex flex-col lg:flex-row my-5">
      <div className="hidden lg:flex flex-col w-full lg:w-1/2 p-10">
        <div className="flex-grow flex items-start justify-center">
          <img src={bgImage} alt="bgImage" className="max-h-[750px] h-[550px] w-full object-cover border-[40px] border-template6-blue-2" />
        </div>
      </div>
      <div className="flex flex-col w-full lg:w-1/2 gap-y-5 p-10 lg:p-10">
        <div className="text-5xl font-bold mb-5 text-black">Work Experience</div>
        {user?.workExperiences?.map((work, index) => {
          return <WorkExperienceCard key={index} work={work} />;
        })}
      </div>
    </div>
  </div>
  );
};

export default WorkExperience;

//   <div className="w-full flex flex-col p-10 lg:p-20 shadow-md bg-white border-r-[16px] border-template6-green-1">
//   <div className="text-5xl font-bold lg:w-1/2 mb-10">Work Experience</div>
//   <div className="flex flex-col w-full gap-y-5">
//     {user?.workExperiences?.map((work, index) => {
//       return <WorkExperienceCard key={index} work={work} />;
//     })}
//   </div>
// </div>
