import { useDispatch, useSelector } from 'react-redux';
import { updateDisplay } from '../../../actions/psychrometricAction';

const PsychometricHeader = ({ psychometric }) => {
  const dispatch = useDispatch();

  const lang = useSelector((state) => state.language.lang);

  const handleDisplay = (event) => {
    const value = {
      showResult: event.target.checked === true ? 1 : 0
    };

    dispatch(updateDisplay(value));
  };

  return (
    <div className="relative">
      <div className="flex flex-col-reverse lg:flex-row justify-between lg:items-center gap-2">
        <div className="text-2xl font-bold italic">{psychometric?.resultType?.name}</div>
        <div className="flex flex-row gap-x-4 items-center">
          <div className="text-base font-nunito font-semibold leading-normal text-oppty-grey-12">
            {lang.generalShowInProfile}
          </div>

          <div className="inline-flex items-center">
            <div className="relative inline-block w-8 h-4 rounded-full cursor-pointer">
              <input
                id="display"
                name="display"
                type="checkbox"
                checked={psychometric?.showResult}
                onChange={handleDisplay}
                className="absolute w-8 h-4 transition-colors duration-300 rounded-full appearance-none cursor-pointer peer bg-blue-gray-100 checked:bg-oppty-purple-1 peer-checked:border-oppty-purple-1 peer-checked:before:bg-oppty-purple-1"
              />
              <label
                htmlFor="display"
                className="before:content[''] absolute top-2/4 -left-1 h-6 w-6 -translate-y-2/4 cursor-pointer rounded-full border border-blue-gray-100 bg-white checked:bg-oppty-blue peer:bg-oppty-blue peer-checked:bg-oppty-blue shadow-md transition-all duration-300 before:absolute before:top-2/4 before:left-2/4 before:block before:h-10 before:w-10 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-oppty-blue before:opacity-0 before:transition-opacity hover:before:opacity-10 peer-checked:translate-x-full peer-checked:border-oppty-blue peer-checked:before:bg-oppty-blue"
              >
                <div
                  className="inline-block p-5 rounded-full top-2/4 left-2/4 -translate-x-2/4 -translate-y-2/4"
                  data-ripple-dark="true"
                ></div>
              </label>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-2 text-base text-gray-600">{psychometric?.resultType?.description}</div>
      <div className="flex flex-wrap gap-3 mt-5">
        {psychometric?.traitList?.map((trait, index) => {
          return (
            <div
              key={index}
              className="rounded-lg shadow-md w-[48%] p-3 border"
              style={{ backgroundColor: trait.backgroundColor }}
            >
              <div className="mb-2 text-lg font-bold underline" style={{ color: trait.titleColor }}>
                {trait.traitTypeName}
              </div>
              <div className="flex flex-col lg:flex-row gap-3 items-start justify-start">
                <div
                  style={{ backgroundColor: trait.titleColor }}
                  className="flex-shrink-0 w-10 h-10 rounded-full flex justify-center items-center text-xl text-white font-bold shadow-md"
                >
                  {trait?.traitShort}
                </div>
                <div className="text-sm font-medium text-black/80">{trait.traitName}</div>
              </div>
            </div>
          );
        })}
      </div>
      <div className="mt-10 text-3xl font-bold">{lang.generalStrength}</div>
      <div className="mt-3 flex flex-col gap-3">
        {psychometric?.strengthList?.map((strength, index) => {
          return (
            <div key={index}>
              <div className="text-lg font-semibold">{strength?.strengthTitle}</div>
              <div className="text-sm font-medium text-gray-400">{strength?.strengthDescription}</div>
              <div></div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default PsychometricHeader;
