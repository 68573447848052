const EducationCard = ({ education }) => {
  return (
    <div className="flex flex-col">
      <div className="text-template6-purple-1 text-2xl font-bold">{education?.institution}</div>
      <div className="text-gray-600 text-base font-semibold">
        {education?.qualification} in {education?.course}
      </div>
      <div className="text-base text-gray-600 rounded-2xl">
        {education?.startMonth} {education?.startYear} -{' '}
        {education?.endYear === 99 || education?.endYear === '99'
          ? 'Current'
          : `${education?.endMonth} ${education?.endYear}`}
      </div>
      {/* <div className="font-medium text-xs text-gray-600 mt-2">{education?.description}</div> */}
      <div className="w-full pt-2" dangerouslySetInnerHTML={{ __html: education?.description }}></div>
    </div>
  );
};

export default EducationCard;
